import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import reportWebVitals from './reportWebVitals';
import './i18n/config';

const theme = {
  colors: {
    primary: '#8a2be2',     // Color principal (morado)
    secondary: '#f8f4ff',   // Color secundario (morado claro)
    accent: '#6a1b9a',      // Color de acento (morado oscuro)
    text: {
      primary: '#333333',
      secondary: '#666666',
    },
    background: {
      primary: '#ffffff',
      secondary: '#f8f9fa',
    },
    border: '#e0e0e0',
    hover: {
      primary: '#7B1FA2',
      secondary: '#f0e6ff',
    }
  },
  shadows: {
    small: '0 2px 4px rgba(0,0,0,0.1)',
    medium: '0 4px 6px rgba(0,0,0,0.1)',
    large: '0 8px 16px rgba(0,0,0,0.1)',
  },
  borderRadius: {
    small: '8px',
    medium: '12px',
    large: '25px',
  }
};

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
);

reportWebVitals();
