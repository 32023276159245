import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { parseCartelesHTML } from './utils/cartelesParser';
import { parseFelpeyosHTML } from './utils/felpeyosParser';
import { Link } from 'react-router-dom';

const CartelesFiestaContainer = styled.div`
  padding: 20px;
  background-color: ${props => props.theme.colors.secondary};
  border-radius: ${props => props.theme.borderRadius.large};
  box-shadow: ${props => props.theme.shadows.large};
  text-align: center;
  font-family: 'Arial', sans-serif;
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Title = styled.h1`
  font-size: clamp(1.5rem, 3vw, 2.2rem);
  color: ${props => props.theme.colors.primary};
  margin-bottom: 30px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  padding: 0 10px;
`;

const CartelGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 25px;
  margin-top: 20px;
  padding: 0 10px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 15px;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    gap: 12px;
    margin-top: 10px;
  }
`;

const CartelCard = styled.div`
  background-color: ${props => props.theme.colors.background.primary};
  border-radius: ${props => props.theme.borderRadius.medium};
  padding: 15px;
  box-shadow: ${props => props.theme.shadows.small};
  transition: transform 0.3s;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 300px;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    transform: translateY(-5px);
    box-shadow: ${props => props.theme.shadows.medium};
  }

  @media (max-width: 768px) {
    padding: 12px;
    min-width: 180px;
  }

  @media (max-width: 480px) {
    padding: 10px;
    max-width: 100%;
    
    &:hover {
      transform: none; // Desactivar hover en móviles
    }
  }
`;

const CartelImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-top: 15px;
  border-radius: 10px;
  transition: transform 0.3s;
  width: 250px;
  object-fit: cover;
  
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    width: 220px;
    margin-top: 12px;
  }

  @media (max-width: 480px) {
    width: 200px;
    margin-top: 10px;
    
    &:hover {
      transform: none; // Desactivar hover en móviles
    }
  }
`;

const Year = styled(Link)`
  font-size: clamp(1.2rem, 2vw, 1.5rem);
  color: ${props => props.theme.colors.text.primary};
  margin: 10px 0;
  text-decoration: none;
  
  &:hover {
    color: ${props => props.theme.colors.primary};
  }

  @media (max-width: 480px) {
    margin: 8px 0;
  }
`;

const Design = styled.h2`
  font-size: clamp(0.9rem, 1.5vw, 1.2rem);
  color: ${props => props.theme.colors.text.secondary};
  margin: 5px 0;
  text-align: center;
  padding: 0 5px;

  @media (max-width: 480px) {
    margin: 4px 0;
  }
`;

const SectionTitle = styled(Title)`
  font-size: clamp(1.3rem, 2.5vw, 1.8rem);
  margin-top: 40px;

  @media (max-width: 768px) {
    margin-top: 30px;
  }

  @media (max-width: 480px) {
    margin-top: 20px;
  }
`;

const LoadingMessage = styled.div`
  font-size: clamp(1rem, 1.5vw, 1.2rem);
  color: #666;
  text-align: center;
  padding: 20px;

  @media (max-width: 480px) {
    padding: 15px;
  }
`;

const ErrorMessage = styled.div`
  color: #ff0000;
  text-align: center;
  padding: 20px;
  font-size: clamp(0.9rem, 1.5vw, 1.1rem);

  @media (max-width: 480px) {
    padding: 15px;
  }
`;

const CartelesFiesta = () => {
  const [data, setData] = useState(null);
  const [legacyData, setLegacyData] = useState(null);
  const [felpeyosData, setFelpeyosData] = useState(null);
  const [apiFelpeyosData, setApiFelpeyosData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = process.env.REACT_APP_API_TOKEN;

    const getApiData = axios.get(`${apiUrl}/api/carteles-fiestas?populate=*`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const getApiFelpeyosData = axios.get(`${apiUrl}/api/carteles-felpeyos?populate=*`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const getLegacyData = fetch('/carteles.html')
      .then(response => response.text())
      .then(html => {
        const cartelesData = parseCartelesHTML(html);
        const felpeyosData = parseFelpeyosHTML(html);
        return { carteles: cartelesData, felpeyos: felpeyosData };
      });

    Promise.all([getApiData, getLegacyData, getApiFelpeyosData])
      .then(([apiResponse, legacyResponse, apiFelpeyosResponse]) => {
        if (apiResponse.data.data && apiResponse.data.data.length > 0) {
          setData(apiResponse.data.data);
        }
        
        if (legacyResponse.carteles.data && legacyResponse.carteles.data.length > 0) {
          setLegacyData(legacyResponse.carteles.data);
        }

        if (legacyResponse.felpeyos.data && legacyResponse.felpeyos.data.length > 0) {
          setFelpeyosData(legacyResponse.felpeyos.data);
        }

        if (apiFelpeyosResponse.data.data && apiFelpeyosResponse.data.data.length > 0) {
          setApiFelpeyosData(apiFelpeyosResponse.data.data);
        }
      })
      .catch(error => {
        console.error('Error al obtener los datos:', error);
        setError('Error al cargar los datos');
      });
  }, []);

  const getImageUrl = (item) => {
    if (!item) return '';
    
    let imageUrl;
    if (item.Cartel && item.Cartel.length > 0 && item.Cartel[0].url) {
      imageUrl = item.Cartel[0].url;
    } else if (item.attributes?.Cartel?.data?.[0]?.attributes?.url) {
      imageUrl = item.attributes.Cartel.data[0].attributes.url;
    } else if (item.attributes?.Imagen?.data?.[0]?.attributes?.url) {
      imageUrl = item.attributes.Imagen.data[0].attributes.url;
    }

    if (!imageUrl) return '';
    
    return imageUrl.startsWith('/juevesdecomadres') 
      ? imageUrl 
      : `${process.env.REACT_APP_API_URL}${imageUrl}`;
  };

  if (error) return <ErrorMessage>Error: {error}</ErrorMessage>;
  if (!data && !legacyData && !felpeyosData && !apiFelpeyosData) return <LoadingMessage>Cargando...</LoadingMessage>;

  const allCarteles = [
    ...(data || []),
    ...(legacyData || [])
  ].sort((a, b) => {
    const yearA = new Date(a.Year).getFullYear();
    const yearB = new Date(b.Year).getFullYear();
    return yearB - yearA;
  });

  const allFelpeyos = [
    ...(apiFelpeyosData || []),
    ...(felpeyosData || [])
  ].sort((a, b) => {
    const yearA = new Date(a.Year || a.attributes?.Year).getFullYear();
    const yearB = new Date(b.Year || b.attributes?.Year).getFullYear();
    return yearB - yearA;
  });

  return (
    <CartelesFiestaContainer>
      <Title>Carteles de la Fiesta JUEVES DE COMADRES 1987 - {new Date().getFullYear()}</Title>
      <CartelGrid>
        {allCarteles.map((cartel, index) => (
          <CartelCard key={`${cartel.id}-${index}`}>
            <Year to={`/comadres-y-felpeyo/${new Date(cartel.Year || cartel.attributes?.Year).getFullYear()}`}>
              Año {new Date(cartel.Year || cartel.attributes?.Year).getFullYear()}
            </Year>
            <Design>{cartel.Diseno || cartel.attributes?.Diseno}</Design>
            <CartelImage 
              src={getImageUrl(cartel)} 
              alt={
                cartel.attributes?.Cartel?.data?.[0]?.attributes?.alternativeText || 
                cartel.attributes?.Imagen?.data?.[0]?.attributes?.alternativeText || 
                `Cartel ${index + 1}`
              } 
            />
          </CartelCard>
        ))}
      </CartelGrid>

      <SectionTitle>FELPEYOS 1990 - {new Date().getFullYear()}</SectionTitle>
      <Design>Autor: Jorge Enrique Maojo Vilasboas</Design>
      <CartelGrid>
        {allFelpeyos.map((felpeyo, index) => (
          <CartelCard key={`${felpeyo.id}-${index}`}>
            <Year to={`/comadres-y-felpeyo/${new Date(felpeyo.Year || felpeyo.attributes?.Year).getFullYear()}`}>
              Año {new Date(felpeyo.Year || felpeyo.attributes?.Year).getFullYear()}
            </Year>
            <CartelImage 
              src={getImageUrl(felpeyo)} 
              alt={
                felpeyo.attributes?.Imagen?.data?.[0]?.attributes?.alternativeText || 
                `Felpeyo ${index + 1}`
              } 
            />
          </CartelCard>
        ))}
      </CartelGrid>
    </CartelesFiestaContainer>
  );
};

export default CartelesFiesta;