import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const FiestaContainer = styled.div`
  max-width: 1200px;
  margin: 3rem auto;
  padding: 2.5rem;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: 0 12px 35px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    padding: 1.5rem;
    margin: 1.5rem;
  }
`;

const Title = styled.h1`
  font-size: 2.8rem;
  color: #2d3436;
  margin-bottom: 2.5rem;
  text-align: center;
  font-weight: 800;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 4px;
    background: linear-gradient(90deg, #FF6B6B, #FF8E53);
    border-radius: 4px;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const ImageGallery = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 3rem 0;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FiestaImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: contain;
  border-radius: 16px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
  background-color: #f8f9fa;
  padding: 1rem;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
  }
`;

const ContentText = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  color: #4a4a4a;
  margin-bottom: 1.5rem;
  text-align: justify;
  
  &:first-of-type {
    margin-top: 2rem;
  }
`;

const LoadingContainer = styled.div`
  text-align: center;
  padding: 4rem;
  color: #FF6B6B;
  font-size: 1.4rem;
  font-weight: 500;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
  margin: 2rem auto;
  max-width: 600px;
`;

const ErrorContainer = styled.div`
  text-align: center;
  padding: 2rem;
  color: #ff4757;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
  margin: 2rem auto;
  max-width: 600px;
  font-weight: 500;
`;

const NuestraFiesta = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = process.env.REACT_APP_API_TOKEN;
        
        const response = await axios.get(`${apiUrl}/api/nuestra-fiestas?populate=*`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (response.data && response.data.data[0]) {
          const fiestaData = response.data.data[0].attributes;
          setData({
            title: fiestaData.Title,
            content: fiestaData.content,
            image: fiestaData.Image?.data || null
          });
        } else {
          setError('No se encontraron datos de Nuestra Fiesta');
        }
      } catch (error) {
        console.error('Error al cargar los datos:', error);
        setError('Error al cargar los datos. Por favor, intente más tarde.');
      }
    };

    fetchData();
  }, []);

  const renderContent = (content) => {
    if (!Array.isArray(content)) return null;

    return content.map((block, index) => {
      if (block.type === 'paragraph' && block.children) {
        // Filtra los párrafos vacíos
        const text = block.children.map(child => child.text).join('').trim();
        if (!text) return null;

        return (
          <ContentText key={`text-${index}`}>
            {text}
          </ContentText>
        );
      }
      return null;
    });
  };

  if (error) {
    return <ErrorContainer>{error}</ErrorContainer>;
  }

  if (!data) {
    return <LoadingContainer>Cargando...</LoadingContainer>;
  }

  return (
    <FiestaContainer>
      <Title>{data.title}</Title>
      {data.content && renderContent(data.content)}
      {data.image && (
        <ImageGallery>
          <FiestaImage
            src={`${process.env.REACT_APP_API_URL}${data.image.attributes.url}`}
            alt={data.image.attributes.alternativeText || 'Imagen de nuestra fiesta'}
          />
        </ImageGallery>
      )}
    </FiestaContainer>
  );
};

export default NuestraFiesta;