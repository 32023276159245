import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PageWrapper from './components/PageWrapper';

const HistoryContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Title = styled.h1`
  color: #2d3436;
  text-align: center;
  margin-bottom: 3rem;
  font-size: 2.5rem;

  @media (max-width: 768px) {
    font-size: 1.8rem;
    margin-bottom: 2rem;
  }
`;

const YearGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`;

const YearCard = styled(Link)`
  background-color: #ffffff;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  text-decoration: none;
  color: inherit;

  @media (max-width: 768px) {
    padding: 1rem;
    
    &:hover {
      transform: none;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }
  }
`;

const YearTitle = styled.h2`
  color: #2d3436;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  text-align: center;
  border-bottom: 2px solid #ffd700;
  padding-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

const AwardsList = styled.ul`
  list-style: none;
  padding: 0;
`;

const AwardItem = styled.li`
  margin-bottom: 1rem;
  color: #636e72;
  font-size: 0.9rem;
  padding: 0.5rem;
  border-radius: 8px;

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0.75rem;
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  strong {
    color: #2d3436;
    display: block;
    margin-bottom: 0.3rem;
  }
`;

const RecipientsList = styled.span`
  display: block;
  margin-top: 0.2rem;
  font-style: italic;
  
  @media (max-width: 768px) {
    line-height: 1.4;
  }
`;

const historicalData = [

        {
          year: 2024,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Doña Mónica Alario Gavilán"
            },
            {
              title: "Felpeyu",
              recipient: "Luis Rubiales"
            },
            {
              title: "Babayu",
              recipient: "Pablo Motos"
            }
          ]
        },
        {
          year: 2023,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Patricia Sornosa Flores"
            },
            {
              title: "Comadre de Oro especial",
              recipient: "Anabel Santiago Sánchez"
            },
            {
              title: "Felpeyu",
              recipient: "Colegio Mayor Elías Ahuja"
            }
          ]
        },
        {
          year: 2022,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Alianza \"Contra el borrado de las mujeres\""
            },
            {
              title: "Felpeyu",
              recipient: "Presidente del Rayo Vallecano Raúl Martín Presa y al entrenador Carlos Santiso"
            }
          ]
        },
        {
          year: 2021,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Mabel Lozano"
            },
            {
              title: "Felpeyu",
              recipient: "Trifachito"
            },
            {
              title: "Babayu",
              recipient: "Alfredo Canteli Fernández"
            }
          ]
        },
        {
          year: 2020, 
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Doña Najat el Hachmi"
            },
            {
              title: "Comadre de Oro",
              recipient: "Doña Almudena Grandes Hernández"
            },
            {
              title: "Felpeyu",
              recipient: "Real Federación Española de Fútbol (RFEF)"
            }
          ]
        },
        {
          year: 2019,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Dña Amelia Tiganus"
            },
            {
              title: "Comadre de Oro especial",
              recipient: "Dña. Pilar Sánchez Vicente"
            },
            {
              title: "Felpeyu",
              recipient: "D. Pablo Casado Blanco"
            },
            {
              title: "Babayu",
              recipient: "D. Enrique Cerezo Torres"
            }
          ]
        },
        {
          year: 2018,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Dña. Rosa Cobo Bedia"
            },
            {
              title: "Felpeyu",
              recipients: ["Agustín Martínez Becerra", "Juan Manuel Canales Cid", "Jesús Pérez Pérez"]
            }
          ]
        },
        {
          year: 2017,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Mar Esquembre Cerdá"
            },
            {
              title: "Comadre de Oro especial",
              recipient: "Camen Gómez Ojea"
            },
            {
              title: "Felpeyu",
              recipient: "David Pérez"
            },
            {
              title: "Babayu",
              recipient: "Felix de Azua"
            }
          ]
        },
        {
          year: 2016,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Ana de Miguel"
            },
            {
              title: "Comadre de Oro especial",
              recipient: "Alicia Miyares"
            },
            {
              title: "Felpeyu",
              recipient: "Francisco Javier Martinez Fernández"
            },
            {
              title: "Babayu",
              recipient: "Salvador Hernández Hernández"
            }
          ]
        },
        {
          year: 2015,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Pilar Aguilar Carrasco"
            },
            {
              title: "Felpeyu",
              recipient: "Asociación Galgos 112"
            },
            {
              title: "Babayu",
              recipient: "Miguel Arias Cañete"
            }
          ]
        },
        {
          year: 2014,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "CIMA (Asociación de mujeres cineastas y de medios audiovisualtes)"
            },
            {
              title: "Felpeyu",
              recipient: "Mariano Rajoy"
            },
            {
              title: "Babayu",
              recipient: "Toni Cantó"
            }
          ]
        },
        {
          year: 2013,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Carme Valls-Llobet"
            },
            {
              title: "Felpeyu",
              recipient: "José Ignacio Wert"
            },
            {
              title: "Babayu",
              recipient: "Ignacio González"
            }
          ]
        },
        {
          year: 2012,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Alicia Puleo García"
            },
            {
              title: "Felpeyu",
              recipient: "Alberto Ruíz Gallardón"
            }
          ]
        },
        {
          year: 2011,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Paz Fernández Felgueroso"
            },
            {
              title: "Felpeyu",
              recipient: "Francisco Javier León de la Riva"
            },
            {
              title: "Babayu",
              recipient: "Salvador Sostres"
            }
          ]
        },
        {
          year: 2010,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Icíar Bollaín"
            },
            {
              title: "Felpeyu",
              recipient: "Francisco Serrano"
            },
            {
              title: "Babayu",
              recipient: "Juan Manuel de Prada"
            }
          ]
        },
        {
          year: 2009,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Soledad Murillo de la Vega"
            },
            {
              title: "Mención Especial",
              recipient: "Diario \"Público\""
            },
            {
              title: "Felpeyu",
              recipient: "OBO Bettermann, S.A."
            },
            {
              title: "Babayu",
              recipient: "Francisco Serrano"
            }
          ]
        },
        {
          year: 2008,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "ACAI (Asociación de clínicas acreditadas para la interrupción voluntaria del embarazo)"
            },
            {
              title: "Comadre de Oro Especial",
              recipient: "Asociación de mujeres juristas THEMIS"
            },
            {
              title: "Felpeyu",
              recipient: "José Luis Sierra Fernández"
            },
            {
              title: "Babayu Mayor",
              recipient: "José Luis Requero Ibáñez"
            }
          ]
        },
        {
          year: 2007,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Soledad Gallego Díaz"
            },
            {
              title: "Comadre de Oro Especial",
              recipient: "Asociación Feminista de Asturias (AFA)"
            },
            {
              title: "Felpeyu",
              recipient: "Real Academia Española de la Lengua"
            }
          ]
        },
        {
          year: 2006,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Mª Teresa Fernández de la Vega"
            },
            {
              title: "Comadre de Oro Especial",
              recipient: "Asociación de mujeres gitanas de Gijón"
            },
            {
              title: "Felpeyu",
              recipient: "Amando de Miguel"
            }
          ]
        },
        {
          year: 2005,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Isabel Morant Deusa"
            },
            {
              title: "Comadre de Oro Especial",
              recipient: "Foro de la Calzada (Gijón)"
            },
            {
              title: "Felpeyu",
              recipients: ["Monseñor Gea Escolano", "Monseñor Noguer Carmona"]
            },
            {
              title: "Babayu Mayor",
              recipient: "Federico Ortiz Cañavate"
            }
          ]
        },
        {
          year: 2004,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Gemma Nierga"
            },
            {
              title: "Felpeyu",
              recipient: "Conferencia Episcopal Española"
            },
            {
              title: "Babayu Permanente",
              recipient: "Manuel Fraga Iribarne"
            }
          ]
        },
        {
          year: 2003,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Plataforma de Mujeres Artistas Contra la Violencia"
            },
            {
              title: "Comadre de Oro Especial",
              recipient: "Aida Fuentes Concheso"
            },
            {
              title: "Felpeyu",
              recipient: "Ángel Sagardoy Varela"
            },
            {
              title: "Babayu Mayor",
              recipient: "Gustavo Bueno \"SENIOR\""
            }
          ]
        },
        {
          year: 2002,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "María José Varela Portela"
            },
            {
              title: "Comadre de Oro Especial",
              recipient: "María de las Alas Pumariño"
            },
            {
              title: "Felpeyu",
              recipient: "Jesús María Díez Fernández"
            }
          ]
        },
        {
          year: 2001,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Rosario Fernández Hevia"
            },
            {
              title: "Felpeyu",
              recipient: "Círculo de Empresarios de Madrid"
            }
          ]
        },
        {
          year: 2000,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Carmen Alborch"
            },
            {
              title: "Comadre de Oro Especial",
              recipient: "Sara Suárez Solís"
            },
            {
              title: "Felpeyu",
              recipient: "Cofradía de pescadores \"El Palmar\" de la Albufera"
            }
          ]
        },
        {
          year: 1999,
          awards: [
            {
              title: "Comadre de Oro",
              recipients: ["Cristina del Valle", "María Teresa"]
            },
            {
              title: "Felpeyu",
              recipient: "Federación Asturiana de Fútbol"
            }
          ]
        },
        {
          year: 1998,
          awards: [
            {
              title: "Comadre de Oro",
              recipients: ["Juana Doña", "Carlota Bustelo"]
            },
            {
              title: "Comadre de Oro Especial",
              recipient: "Mujeres de Chiapas"
            },
            {
              title: "Felpeyu",
              recipient: "Lo que necesitas es amor (Antena 3 TV)"
            }
          ]
        },
        {
          year: 1997,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Lidia Falcón"
            },
            {
              title: "Felpeyu",
              recipient: "Villa Pastur"
            }
          ]
        },
        {
          year: 1996,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Celia Amorós"
            },
            {
              title: "Felpeyu",
              recipient: "RIDEA (Real Instituto de Estudios Asturianos)"
            }
          ]
        },
        {
          year: 1995,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Ana María Pérez del Campo"
            },
            {
              title: "Felpeyu",
              recipient: "Dirección Escuela Universitaria de Magisterio de Oviedo"
            }
          ]
        },
        {
          year: 1994,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Maruja Torres"
            },
            {
              title: "Felpeyu",
              recipient: "Chumy Chumez"
            }
          ]
        },
        {
          year: 1993,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Amelia Valcárcel"
            },
            {
              title: "Felpeyu",
              recipient: "Tele 5"
            }
          ]
        },
        {
          year: 1992,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Dolores Medio"
            },
            {
              title: "Mención Especial",
              recipient: "Colegio Público García Lorca (La Engañifa)"
            },
            {
              title: "Felpeyu",
              recipient: "Miguel González Calderón"
            }
          ]
        },
        {
          year: 1991,
          awards: [
            {
              title: "Comadre de Oro",
              recipient: "Victoria Sau"
            },
            {
              title: "Comadre de Oro Especial",
              recipient: "María Jesús Arrieta Gallastegui (Machús)"
            },
            {
              title: "Mención Especial",
              recipient: "Trabajadoras de Confecciones Gijón"
            },
            {
              title: "Felpeyu",
              recipient: "Enrique Múgica (Diputado y Ministro de Justicia) "
            }
          ]
        },
        {
          year: 1990,
          awards: [
            {
              title: "Comadre de Oro",
              recipients: ["Elisa Sesma", "Mari Cruz Landa", "Pablo Sánchez", "Josep-Vincent Marqués"]
            },
            {
            title: "Felpeyu",
            recipient: "Comisión de Verificación y Control de Publicidad de TVE"
            }
            ]
            },
            {
            year: 1989,
            awards: [
            {
            title: "Comadre de Oro",
            recipient: "Cristina Almeida"
            },
            {
            title: "Felpeyu",
            recipient: "Diario El Comercio"
            }
            ]
            },
            {
            year: 1988,
            awards: [
            {
            title: "Comadre de Oro",
            recipient: "Carmen Sarmiento"
            },
            {
            title: "Felpeyu",
            recipient: "Fernando Poblet FERPO"
            }
            ]
            },
            {
            year: 1987,
            awards: [
            {
            title: "Comadre de Oro",
            recipients: ["Dulce María Frutos", "Teresa Menéndez", "Ana Álvarez", "Blanca Esther González", "Mª de Pilar García García"]
            },
            {
            title: "Felpeyu",
            recipient: "Carlos del Valle"
            }
            ]
            }
  // ... (todos los datos que proporcionaste)
];

const ComadresHistory = () => {
  const renderRecipients = (recipient) => {
    if (Array.isArray(recipient)) {
      return recipient.join(", ");
    }
    return recipient;
  };

  return (
    <PageWrapper>
      <HistoryContainer>
        <Title>Historia de Comadres y Felpeyo</Title>
        <YearGrid>
          {historicalData.map((yearData) => (
            <YearCard key={yearData.year} to={`/comadres-y-felpeyo/${yearData.year}`}>
              <YearTitle>{yearData.year}</YearTitle>
              <AwardsList>
                {yearData.awards.map((award, index) => (
                  <AwardItem key={index}>
                    <strong>{award.title}</strong>
                    <RecipientsList>
                      {award.recipients ? 
                        renderRecipients(award.recipients) : 
                        renderRecipients(award.recipient)}
                    </RecipientsList>
                  </AwardItem>
                ))}
              </AwardsList>
            </YearCard>
          ))}
        </YearGrid>
      </HistoryContainer>
    </PageWrapper>
  );
};

export default ComadresHistory; 