import React from 'react';
import styled from 'styled-components';
import EventsSidebar from './EventsSidebar';

const MainContainer = styled.div`
  max-width: 100%;
  margin: 0 auto;
  padding: 2rem;
  padding-bottom: 100px;
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 2rem;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const LeftSidebar = styled.div`
  flex: 0 0 10%; // Fixed width for EventsSidebar
  position: sticky;
  top: 2rem;
  height: fit-content;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const MainContentArea = styled.div`
  flex: 1;
  width: 100%;
`;

const MainContent = styled.div`
  width: 100%;
  min-width: 0;
`;

const SidebarSection = styled.div`
  padding: 1rem;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const MainTitle = styled.h1`
  font-family: 'Playfair Display', serif;
  font-size: 3rem;
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  font-family: 'Playfair Display', serif;
  color: var(--primary-color);
  margin-bottom: 1rem;
`;

const ScrollButton = styled.button`
  position: fixed;
  bottom: 150px;
  right: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #8a2be2;
  color: white;
  border: none;
  cursor: pointer;
  opacity: 0;
  transform: translateY(100px);
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0,0,0,0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  z-index: 1000;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  &:hover {
    background: #7825c4;
    transform: translateY(-3px);
    box-shadow: 0 6px 20px rgba(0,0,0,0.2);
  }

  @media (max-width: 1024px) {
    bottom: 100px;
  }
`;

const MainSection = ({ children, showLeftSidebar = true }) => {
  const [showScroll, setShowScroll] = React.useState(false);

  React.useEffect(() => {
    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 400) {
        setShowScroll(true);
      } else if (showScroll && window.pageYOffset <= 400) {
        setShowScroll(false);
      }
    };

    window.addEventListener('scroll', checkScrollTop);
    return () => window.removeEventListener('scroll', checkScrollTop);
  }, [showScroll]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <MainContainer>
      <ContentWrapper>
        {showLeftSidebar && (
          <LeftSidebar>
            <EventsSidebar />
          </LeftSidebar>
        )}

        <MainContentArea>
          <MainContent>
            {children}
          </MainContent>
        </MainContentArea>
      </ContentWrapper>
      
      <ScrollButton title="Volver arriba" placement="top"
        onClick={scrollToTop}
        className={showScroll ? 'visible' : ''}
        aria-label="Volver arriba"
      >
        ↑
      </ScrollButton>
    </MainContainer>
  );
};

export default MainSection;
