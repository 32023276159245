import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'es',
    defaultNS: 'translation',
    lng: 'es',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      es: {
        translation: {
          'AGENDA': 'Agenda',
          'weekDays': {
            'L': 'L',
            'M': 'M',
            'X': 'X',
            'J': 'J',
            'V': 'V',
            'S': 'S',
            'D': 'D'
          }
        }
      }
    }
  });

export default i18n; 