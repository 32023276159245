import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const BlogContent = styled.div`
  .blog-content {
    margin: 1rem 0;
    line-height: 1.6;
    
    img {
      max-width: 100%;
      height: auto;
      margin: 1rem 0;
    }

    p {
      margin-bottom: 1rem;
    }

    h1, h2, h3, h4, h5, h6 {
      margin: 1.5rem 0 1rem;
    }
  }
`;

const BlogPages = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const PageItem = styled.article`
  margin-bottom: 3rem;
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);

  h2 {
    color: #4a1a99;
    margin-bottom: 1rem;
  }

  .publish-date {
    color: #666;
    font-style: italic;
    margin-bottom: 1.5rem;
  }
`;

const ReadMoreLink = styled.a`
  display: inline-block;
  margin-top: 1rem;
  color: #6633cc;
  text-decoration: none;
  font-weight: 500;
  
  &:hover {
    text-decoration: underline;
  }
`;

const BlogContainer = () => {
  const [pages, setPages] = useState([]);
  const blogId = '1555415857002703612';  // Replace with your Blogger Blog ID
  const apiKey = 'AIzaSyBCuUWquNrat7XA0oeYfexifEjP5V7wRGE';  // Replace with your API key

  
  useEffect(() => {
    const fetchPages = async () => {
      try {
        const response = await axios.get(
          `https://www.googleapis.com/blogger/v3/blogs/${blogId}/pages?key=${apiKey}`
        );
        setPages(response.data.items);
      } catch (error) {
        console.error('Error fetching pages:', error);
      }
    };
    fetchPages();
  }, []);

  return (
    <BlogPages>
      {pages.map((page) => (
        <PageItem key={page.id}>
          <h2>{page.title}</h2>
          <p className="publish-date">
            Publicado: {new Date(page.published).toLocaleDateString('es-ES', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })}
          </p>
          <BlogContent 
            dangerouslySetInnerHTML={{ __html: page.content }} 
            className="blog-content"
          />
          <ReadMoreLink 
            href={page.url} 
            target="_blank" 
            rel="noopener noreferrer"
          >
            Leer en el blog original →
          </ReadMoreLink>
        </PageItem>
      ))}
    </BlogPages>
  );
};

export default BlogContainer;
