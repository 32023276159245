import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import MainSection from './MainSection';
import Home from './TrenDeLaLibertad';
import TrenDeLaLibertad from './TrenDeLaLibertad';
import LaTertulia from './LaTertulia';
import Historia from './Historia';
import Fines from './Fines';
import Estatutos from './Estatutos';
import Galeria from './galeria';
import Hemeroteca from './hemeroteca';
import JuevesComadres from './Jueves_Comadres'; 
import NuestraFiesta from './NuestraFiesta';
import CartelesFiesta from './CartelesFiesta';
import ComadresYFelpeyo from './ComadresYFelpeyo';
import Libros from './Libros';
import CD from './CD';
import LandingPage from './LandingPage';
import Agenda from './Agenda';
import Enlace from './enlace';
import OtrasNoticias from './OtrasNoticias';
import NoticiasPropias from './NoticiasPropias';
import ComadresHistory from './ComadresHistory';
import Archivos from './Archivos';
import Memorias from './Memorias'
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/config';
import { useNavigate } from 'react-router-dom';

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <Header />
        <MainSection>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/tren-de-la-libertad" element={<TrenDeLaLibertad />} />
            <Route path="la-tertulia" element={<LaTertulia />}>
              <Route path="historia" element={<Historia />} />
              <Route path="estatutos" element={<Estatutos />} />
            </Route>
            <Route path="hemeroteca" element={<Hemeroteca />}>
              <Route path="noticias-propias" element={<NoticiasPropias />} />
              <Route path="otras-noticias" element={<OtrasNoticias />} />
            </Route>
            <Route path="jueves-comadres" element={<JuevesComadres />} >      
                 <Route path="fiesta" element={<NuestraFiesta />} />
                 <Route path="carteles-fiesta" element={<CartelesFiesta />} />
                 <Route path="comadres-history" element={<ComadresHistory />} />
            </Route>
            <Route path="agenda" element={<Agenda />} />
            <Route path="debate" element={<div>Pantalla para un debate Content</div>} />
            <Route path="galeria" element={<Galeria />} />
            <Route path="publicaciones/libros" element={<Libros />} />
            <Route path="publicaciones/cd" element={<CD />} />
            <Route path="enlaces" element={<Enlace />} />
            <Route path='archivos' element={<Archivos />} />
            <Route path='memorias' element={<Memorias />} />
           <Route path="comadres-y-felpeyo" element={<ComadresHistory />} />
            <Route path="comadres-y-felpeyo/:year" element={<ComadresYFelpeyo />} />
            <Route path="/agenda/:id" element={<Agenda />} />
          </Routes>
        </MainSection>
        <Footer />
      </Router>
    </I18nextProvider>
  );
}

export default App;
