import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const HistoriaContainer = styled.div`
  max-width: 800px;
  margin: 2rem auto;
  padding: 2.5rem;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.12);
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #2d3436;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 700;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 4px;
    background: linear-gradient(90deg, #6c5ce7, #a8a4e6);
    border-radius: 2px;
  }
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  color: #636e72;
  margin-bottom: 1.5rem;
  text-align: justify;
  
  &:first-of-type {
    margin-top: 2rem;
  }
`;

const LoadingContainer = styled.div`
  text-align: center;
  padding: 3rem;
  color: #6c5ce7;
  font-size: 1.2rem;
`;

const ErrorContainer = styled.div`
  text-align: center;
  padding: 2rem;
  color: #d63031;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  margin: 2rem auto;
  max-width: 600px;
`;

const Historia = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = process.env.REACT_APP_API_TOKEN;

    axios.get(`${apiUrl}/api/historias`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        console.log('API Response:', response.data);
        if (response.data && response.data.data && response.data.data[0]) {
          setData(response.data.data[0].attributes);
        } else {
          setError('No se encontraron datos de Historia');
        }
      })
      .catch(error => {
        console.error('Hubo un error al obtener los datos de Historia!', error);
        setError('Error al cargar los datos');
      });
  }, []);

  if (error) {
    return <ErrorContainer>Error: {error}</ErrorContainer>;
  }

  if (!data) {
    return <LoadingContainer>Cargando...</LoadingContainer>;
  }

  const renderChildren = (children) => {
    return children.map((child, index) => {
      if (child.type === 'text') {
        return child.text;
      }
      return null;
    });
  };

  const renderContentBlock = (block, index) => {
    switch (block.type) {
      case 'paragraph':
        return <Paragraph key={index}>{renderChildren(block.children)}</Paragraph>;
      default:
        return null;
    }
  };

  return (
    <HistoriaContainer>
      <Title>{data.title}</Title>
      <div>
        {data.content.map((block, index) => (
          renderContentBlock(block, index)
        ))}
      </div>
    </HistoriaContainer>
  );
}

export default Historia;
