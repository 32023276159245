import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const HemerotecaContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background: #fff;
  box-shadow: 0 0 20px rgba(0,0,0,0.05);
  border-radius: 12px;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #cc99ff;

  img {
    width: 90px;
    height: 60px;
    margin-right: 2rem;
  }

  h2 {
    color: #333;
    font-size: 2rem;
    margin: 0;
  }
`;

const ArticuloContainer = styled.article`
  display: flex;
  align-items: flex-start;
  padding: 1.2rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: #f8f4ff;
    transform: translateX(10px);
  }
`;

const FuenteLogo = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 1.5rem;
  object-fit: contain;
  border-radius: 4px;
`;

const DefaultBullet = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 1.5rem;
  background-color: #cc99ff;
  border-radius: 50%;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 50%;
  }
`;

const ArticuloInfo = styled.div`
  flex: 1;
`;

const Fecha = styled.span`
  font-weight: 600;
  color: #666;
  margin-right: 0.8rem;
`;

const Fuente = styled.span`
  color: #888;
  margin-right: 0.8rem;
  font-style: italic;
`;

const ArticuloLink = styled.a`
  color: #6633cc;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
  
  &:hover {
    color: #4d1a99;
    text-decoration: underline;
  }
`;

const Autor = styled.span`
  color: #888;
  font-size: 0.9rem;
  margin-left: 0.8rem;
  
  &::before {
    content: '·';
    margin-right: 0.8rem;
  }
`;

const OtrasNoticias = () => {
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = process.env.REACT_APP_API_TOKEN;

    // Obtener datos de la API
    axios.get(`${apiUrl}/api/noticias-otras`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(respuesta => {
        if (respuesta.data.data) {
          const datosFormateados = respuesta.data.data.map(item => ({
            fecha: item.attributes.fecha ? 
              new Date(item.attributes.fecha).toLocaleDateString('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
              }) : '',
            titulo: item.attributes.titulo,
            url: item.attributes.url,
            fuente: item.attributes.fuente || '',
            autor: item.attributes.autor || null
          }));

          // Combinar datos de la API con los datos estáticos
          setAllData([...datosFormateados, ...articulos]);
        }
      })
      .catch(error => {
        console.error('Error al obtener los datos:', error);
        setError('Error al cargar las noticias');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // Array de artículos estáticos (tus datos actuales)
  const articulos = [
    {
      fecha: '26/07/2024',
      fuente: 'La Nueva España',
      titulo: 'Noche de vigilia en la plaza Mayor de Gijón en repulsa por los asesinatos machistas',
      url: 'https://www.lne.es/gijon/2024/07/26/noche-vigilia-plaza-mayor-repulsa-106087578.html',
      autor: 'Aitana Pinilla'
    },
    {
      fecha: '18/09/2024',
      fuente: 'elComún.es',
      titulo: 'Hablar como quien tira globos de colores',
      url: 'https://elcomun.es/2024/09/18/hablar-como-quien-tira-globos-de-colores/',
      autor: 'Pilar Aguilar Carrasco'
    },
    {
      fecha: '13/09/2024',
      fuente: 'elDiario',
      titulo: 'La Fiscalía de Murcia se plantea ahora pedir el ingreso en prisión de los empresarios que abusaron de menores',
      url: 'https://www.eldiario.es/murcia/sociedad/fiscalia-murcia-plantea-pedir-prision-proxenetas-cuya-pena-rebajo-caso-violacion-menores_1_11650998.html',
      autor: 'Elisa M. Almagro'
    },
    {
      fecha: '09/09/2024',
      fuente: 'elComún.es',
      titulo: 'Apartheid de género, lo llaman…',
      url: 'https://elcomun.es/2024/09/09/apartheid-de-genero-lo-llaman/',
      autor: 'Pilar Aguilar Carrasco'
    },
    {
      fecha: '09/09/2024',
      fuente: 'Crónica Libre',
      titulo: 'Monstruos normales',
      url: 'https://www.cronicalibre.com/opinion/monstruos-normales-pelicot/',
      autor: 'Juana Gallego'
    },
    {
      fecha: '03/09/2024',
      fuente: 'El País',
      titulo: 'La magistrada Isabel Perelló será la primera mujer en presidir el CGPJ y el Supremo',
      url: 'https://elpais.com/espana/2024-09-03/el-pleno-del-cgpj-arranca-con-acuerdo-para-votar-a-isabel-perello-como-presidenta.html',
      autor: 'Reyes Rincón y Mónica Cebeiro'
    },
    {
      fecha: '22/08/2024',
      fuente: 'InfoLibre',
      titulo: 'Por una solución justa que no sacrifique los logros de las mujeres',
      url: 'https://www.infolibre.es/opinion/plaza-publica/solucion-justa-no-sacrifique-logros-mujeres_129_1866251.html',
      autor: 'Ángeles Álvarez y Lola Venegas'
    },
    {
      fecha: '09/08/2024',
      fuente: 'La Nueva España',
      titulo: 'Paridad olímpica, sí; igualdad, aún no',
      url: 'https://www.lne.es/opinion/2024/08/09/paridad-olimpica-igualdad-106776323.html',
      autor: 'Ana Rodríguez García'
    },
    {
      fecha: '29/07/2024',
      fuente: 'Razón25',
      titulo: 'Escándalo en los Juegos: París permite competir como mujeres a dos boxeadoras que ya fueron descalificadas por dudas sobre su sexo',
      url: 'https://www.larazon.es/deportes/escandalo-juegos-paris-permite-competir-como-mujeres-dos-boxeadoras-que-fueron-descalificadas-dudas-sexo_2024072966a76231c5d7cc0001923de7.html',
      autor: 'I. Trujillo'
    },
    {
      fecha: '13/07/2024',
      fuente: 'Tribuna Feminista',
      titulo: 'La necesaria abolición mundial de la gestación subrogada',
      url: 'https://tribunafeminista.org/2024/07/la-necesaria-abolicion-mundial-de-la-gestacion-subrogada/',
      autor: 'Amparo Mañes'
    },
    {
      fecha: '28/06/2024',
      fuente: 'Periodismo Alternativo',
      titulo: 'La IVE en Asturias. Un modelo singular de referencia para todo el Estado',
      url: 'https://nuevarevolucion.es/la-ive-en-asturias-un-modelo-singular-de-referencia-para-todo-el-estado/',
      autor: 'Cristina Iglesias'
    },
    {
      fecha: '27/06/2024',
      fuente: 'Crónica Libre',
      titulo: 'Lilly Frost: la reina de los vientres de alquiler en México y California, detenida por robo y fraude. «Estafaba a clientes y a las madres subrogadas»',
      url: 'https://www.cronicalibre.com/feminismo-y-sociedad/lilly-frost-vientres/#google_vignette',
      autor: 'Roberto O. García'
    },
    {
      fecha: '18/09/2024',
        fuente: 'elComún.es',
        titulo: 'Hablar como quien tira globos de colores',
        url: 'https://elcomun.es/2024/09/18/hablar-como-quien-tira-globos-de-colores/',
        autor: 'Pilar Aguilar Carrasco'
      },
      {
        fecha: '13/09/2024',
        fuente: 'elDiario',
        titulo: 'La Fiscalía de Murcia se plantea ahora pedir el ingreso en prisión de los empresarios que abusaron de menores',
        url: 'https://www.eldiario.es/murcia/sociedad/fiscalia-murcia-plantea-pedir-prision-proxenetas-cuya-pena-rebajo-caso-violacion-menores_1_11650998.html',
        autor: 'Elisa M. Almagro'
      },
      {
        fecha: '09/09/2024',
        fuente: 'elComún.es',
        titulo: 'Apartheid de género, lo llaman…',
        url: 'https://elcomun.es/2024/09/09/apartheid-de-genero-lo-llaman/',
        autor: 'Pilar Aguilar Carrasco'
      },
      {
        fecha: '09/09/2024',
        fuente: 'Crónica Libre',
        titulo: 'Monstruos normales',
        url: 'https://www.cronicalibre.com/opinion/monstruos-normales-pelicot/',
        autor: 'Juana Gallego'
      },
      {
        fecha: '03/09/2024',
        fuente: 'El País',
        titulo: 'La magistrada Isabel Perelló será la primera mujer en presidir el CGPJ y el Supremo',
        url: 'https://elpais.com/espana/2024-09-03/el-pleno-del-cgpj-arranca-con-acuerdo-para-votar-a-isabel-perello-como-presidenta.html',
        autor: 'Reyes Rincón y Mónica Cebeiro'
      },
      {
        fecha: '22/08/2024',
        fuente: 'InfoLibre',
        titulo: 'Por una solución justa que no sacrifique los logros de las mujeres',
        url: 'https://www.infolibre.es/opinion/plaza-publica/solucion-justa-no-sacrifique-logros-mujeres_129_1866251.html',
        autor: 'Ángeles Álvarez y Lola Venegas'
      },
      {
        fecha: '09/08/2024',
        fuente: 'La Nueva España',
        titulo: 'Paridad olímpica, sí; igualdad, aún no',
        url: 'https://www.lne.es/opinion/2024/08/09/paridad-olimpica-igualdad-106776323.html',
        autor: 'Ana Rodríguez García'
      },
      {
        fecha: '29/07/2024',
        fuente: 'Razón25',
        titulo: 'Escándalo en los Juegos: París permite competir como mujeres a dos boxeadoras que ya fueron descalificadas por dudas sobre su sexo',
        url: 'https://www.larazon.es/deportes/escandalo-juegos-paris-permite-competir-como-mujeres-dos-boxeadoras-que-fueron-descalificadas-dudas-sexo_2024072966a76231c5d7cc0001923de7.html',
        autor: 'I. Trujillo'
      },
      {
        fecha: '13/07/2024',
        fuente: 'Tribuna Feminista',
        titulo: 'La necesaria abolición mundial de la gestación subrogada',
        url: 'https://tribunafeminista.org/2024/07/la-necesaria-abolicion-mundial-de-la-gestacion-subrogada/',
        autor: 'Amparo Mañes'
      },
      {
        fecha: '26/07/2024',
        fuente: 'La Nueva España',
        titulo: 'Noche de vigilia en la plaza Mayor de Gijón en repulsa por los asesinatos machistas',
        url: 'https://www.lne.es/gijon/2024/07/26/noche-vigilia-plaza-mayor-repulsa-106087578.html',
        autor: 'Aitana Pinilla'
      },
      {
        fecha: '28/06/2024',
        fuente: 'Periodismo Alternativo',
        titulo: 'La IVE en Asturias. Un modelo singular de referencia para todo el Estado',
        url: 'https://nuevarevolucion.es/la-ive-en-asturias-un-modelo-singular-de-referencia-para-todo-el-estado/',
        autor: 'Cristina Iglesias'
      },
      {
        fecha: '27/06/2024',
        fuente: 'Crónica Libre',
        titulo: 'Lilly Frost: la reina de los vientres de alquiler en México y California, detenida por robo y fraude. «Estafaba a clientes y a las madres subrogadas»',
        url: 'https://www.cronicalibre.com/feminismo-y-sociedad/lilly-frost-vientres/#google_vignette',
        autor: 'Roberto O. García'
      },  {
        fecha: '25/03/2024',
        fuente: 'Tribuna Feminista',
        titulo: 'Yo no estuve allí',
        url: 'https://tribunafeminista.org/2024/03/yo-no-estaba-alli/',
        autor: 'Victoria Sendón de León'
      },
      {
        fecha: '20/03/2024',
        fuente: 'YouTube',
        titulo: 'Conferencia Cátedra Marcela Lagarde de los Ríos. Facultad de Ciencias Políticas y Sociología',
        url: 'https://www.youtube.com/watch?v=EqstVX7GeEY',
        autor: null
      },
      {
        fecha: '13/03/2024',
        fuente: 'El Mundo',
        titulo: 'Reino Unido veta el uso de bloqueadores de pubertad en menores tras cerrar la clínica en que se trataba a presuntos niños trans',
        url: 'https://www.elmundo.es/espana/2024/03/13/65f18d61fdddfff5478b4589.html',
        autor: 'Quico Alsedo'
      },
      {
        fecha: '12/03/2024',
        fuente: 'Contra el borrado de las mujeres',
        titulo: 'Irlanda dice NO a suprimir la palabra madre de la Constitución',
        url: 'https://contraelborradodelasmujeres.org/irlanda-dice-no-a-suprimir-la-palabra-madre-de-la-constitucion/',
        autor: null
      },
      {
        fecha: '09/03/2024',
        fuente: 'Nortes',
        titulo: 'El feminismo asturiano se pone la kufiya para "dar tira, a golpe de tacón"',
        url: 'https://www.nortes.me/2024/03/09/el-feminismo-asturiano-se-pone-la-kufiya-para-dar-tira-a-golpe-de-tacon/',
        autor: 'Diego Díaz'
      },
      {
        fecha: '08/03/2024',
        fuente: 'El Comercio',
        titulo: 'Asturias reivindica el 8M: «El tren del feminismo no se ha parado ni se parará»',
        url: 'https://www.elcomercio.es/dia-de-la-mujer/asturias-reivindica-tren-feminismo-parado-parara-barbon-20240308140046-nt.html',
        autor: 'Soraya Pérez'
      },
      {
        fecha: '08/03/2024',
        fuente: 'La Nueva España',
        titulo: '"Blindar los derechos de las mujeres en la Constitución", la petición de Barbón en el acto institucional del 8M celebrado en Laviana',
        url: 'https://www.lne.es/cuencas/2024/03/08/tren-feminismo-alta-velocidad-laviana-99199974.html',
        autor: 'David Orihuela'
      },
      {
        fecha: '04/03/2024',
        fuente: 'elPeriódico',
        titulo: 'Francia se convierte en el primer país en el mundo en inscribir el aborto en la Constitución',
        url: 'https://www.elperiodico.com/es/internacional/20240304/francia-blindar-aborto-constitucion-98992755',
        autor: 'Enric Bonet'
      },
      {
        fecha: '29/02/2024',
        fuente: 'La Nueva España',
        titulo: 'Catorce Miradas Violetas y un sentido homenaje a Anita Sirgo',
        url: 'https://www.lne.es/oviedo/2024/02/29/catorce-miradas-violetas-sentido-homenaje-98801328.html',
        autor: 'Elena Peláez'
      },
      {
        fecha: '29/02/2024',
        fuente: 'Nortes',
        titulo: 'Homenaje a las asturianas que "construyen derechos y hacen historia"',
        url: 'https://www.nortes.me/2024/02/29/homenaje-a-las-asturianas-que-construyen-derechos-y-hacen-historia/',
        autor: null
      },
      {
        fecha: '28/02/2024',
        fuente: 'El Comercio',
        titulo: 'Gala homenaje "Miradas Violeta"',
        url: 'https://www.elcomercio.es/asturias/gala-homenaje-miradas-violetas-20240229233436-ga.html',
        autor: 'Alex Piña'
      },
      {
        fecha: '28/02/2024',
        fuente: 'EFE',
        titulo: 'Francia se convierte en el primer país en introducir el derecho al aborto en la Constitución',
        url: 'https://efe.com/mundo/2024-02-28/francia-aborto-constitucion/',
        autor: null
      },
      {
        fecha: '23/02/2024',
        fuente: 'InfoLibre',
        titulo: 'Basta de conflictos inútiles en el feminismo, basta de feminismo elitista',
        url: 'https://www.infolibre.es/opinion/plaza-publica/basta-conflictos-inutiles-feminismo-basta-feminismo-elitista_129_1719846.html',
        autor: 'Rosa Cobo'
      },
      {
        fecha: '16/02/2024',
        fuente: 'YouTube',
        titulo: 'Prostitución: la voz de las supervivientes',
        url: 'https://aboliciondelaprostitucion.wordpress.com/2024/02/18/la-loasp-el-grito-de-las-supervivientes-en-burgos/',
        autor: 'Jornadas La Asociación Feminista Hypatia de Burgos'
      },
      {
        fecha: '09/02/2024',
        fuente: 'El País',
        titulo: 'No me llames zorra',
        url: 'https://1ft.io/proxy?q=https%3A%2F%2Felpais.com%2Fopinion%2F2024-02-09%2Fno-me-llames-zorra.html#',
        autor: 'Najat El Hachmi'
      }, // Previous entries remain the same...
      {
        fecha: '23/01/2024',
        fuente: 'La Nueva España',
        titulo: 'El feminismo explicado a tus hijas (y también a tus hijos)',
        url: 'https://www.lne.es/asturianos/gijon/2024/01/23/feminismo-explicado-hijas-hijos-97223927.html',
        autor: 'Eduardo Lagar'
      },
      {
        fecha: '22/12/2023',
        fuente: 'El Blog de Salagre',
        titulo: 'Este es el año en que las mujeres contraatacaron y ganaron',
        url: 'https://salagre.com/este-es-el-ano-en-que-las-mujeres-contraatacaron-y-ganaron/',
        autor: 'Janice Turner'
      },
      {
        fecha: '20/12/2023',
        fuente: 'Público',
        titulo: 'La feminista Luciana Peker se exilia de Argentina',
        url: 'https://www.publico.es/mujer/feminista-luciana-peker-exilia-argentina.html',
        autor: null
      },
      {
        fecha: '07/12/2023',
        fuente: 'Tribuna Feminista',
        titulo: '¿Qué te hace mujer?',
        url: 'https://tribunafeminista.org/2023/12/que-te-hace-mujer/',
        autor: 'Amparo Mañes'
      },
      {
        fecha: '01/12/2023',
        fuente: 'Euskal Herria',
        titulo: 'La huelga general feminista afianza sus redes y se prepara para profundizar en el Acuerdo Social',
        url: 'https://www.elsaltodiario.com/euskal-herria/huelga-general-feminista-afianza-redes-se-prepara-profundizar-acuerdo-social',
        autor: 'Gessamí Forner'
      },
      {
        fecha: '30/11/2023',
        fuente: 'Público',
        titulo: '"No estamos todas, faltan las internas": la huelga feminista desborda Bilbao entre un mar de paraguas',
        url: 'https://www.publico.es/mujer/no-faltan-internas-huelga-feminista-desborda-bilbao-mar-paraguas.html',
        autor: 'Danilo Albin'
      },
      {
        fecha: '29/11/2023',
        fuente: 'EL ESPAÑOL',
        titulo: 'Las Cortes Valencianas, a favor de la abolición y de multar a los "puteros" con hasta 30.000 euros',
        url: 'https://www.elespanol.com/valencia/20231129/cortes-valencianas-favor-abolicion-multar-puteros-euros/813418819_0.html',
        autor: null
      },
      {
        fecha: '25/11/2023',
        fuente: '65YMÁS.COM',
        titulo: 'Las mujeres mayores no denuncian: "Nadie les ha enseñado lo que es la violencia de género"',
        url: 'https://www.65ymas.com/sociedad/mujeres-mayores-no-denuncian-nadie-ha-ensenado-es-violencia-genero_55389_102.html',
        autor: 'Laura Moro'
      },
      {
        fecha: '04/11/2023',
        fuente: 'Público',
        titulo: "Ava': la cruel realidad de las niñas con discapacidad prostituidas en España",
        url: 'https://www.publico.es/es/opinion/ava-la-cruel-realidad-de-las-ninas-con-discapacidad-prostituidas-en-espana/',
        autor: 'Amelia Tiganus'
      },
      {
        fecha: '27/10/2023',
        fuente: 'El País',
        titulo: 'Datos | La ola de la extrema derecha solo encuentra un dique: el voto femenino',
        url: 'https://elpais.com/internacional/2023-10-27/datos-la-ola-de-la-extrema-derecha-solo-encuentra-un-dique-el-voto-femenino.html',
        autor: 'Borja Andrino y Montse Hidalgo Pérez'
      },
      {
        fecha: '19/10/2023',
        fuente: 'República',
        titulo: 'Protesta masiva ante RTVE por llamar "personas menstruantes" a las mujeres',
        url: 'https://www.republica.com/sociedad/protesta-masiva-ante-rtve-por-llamar-personas-menstruantes-a-las-mujeres-20231019-16381973410/',
        autor: null
      },
      {
        fecha: '09/10/2023',
        fuente: 'El País',
        titulo: 'Claudia Goldin gana el Nobel de Economía por sus estudios pioneros sobre la brecha de género',
        url: 'https://elpais.com/economia/2023-10-09/premio-nobel-de-economia-2023.html',
        autor: 'Ignacio Fariza'
      },
      {
        fecha: '07/10/2023',
        fuente: 'BBC News Mundo',
        titulo: 'Katalin Karikó, la investigadora húngara que fue ignorada y degradada, y acabó ganando el Nobel de Medicina',
        url: 'https://www.bbc.com/mundo/articles/c2j941r8ny3o',
        autor: null
      },
      {
        fecha: '06/10/2023',
        fuente: 'Expansión',
        titulo: 'Narges Mohammadi, Premio Nobel de la Paz 2023 por su lucha contra la opresión de las mujeres en Irán',
        url: 'https://www.expansion.com/sociedad/2023/10/06/651fd1c4e5fdea44688b45c6.html',
        autor: 'Marga Castillo'
      },
      {
        fecha: '02/10/2023',
        fuente: 'Alternativas Económicas',
        titulo: 'Las mujeres dicen ¡basta ya!',
        url: 'https://alternativaseconomicas.coop/articulo/el-tema-del-mes/las-mujeres-dicen-basta-ya',
        autor: 'Mariana Vilnitzky'
      },{
        fecha: '28/09/2023',
        fuente: 'Periodismo alternativo',
        titulo: 'Aborto legal, seguro y accesible y Tribunal Constitucional',
        url: 'https://nuevarevolucion.es/aborto-legal-seguro-y-accesible-y-tribunal-constitucional/',
        autor: 'José Antonio Bosch'
      },
      {
        fecha: '23/09/2023',
        fuente: 'El País',
        titulo: 'Trata de blancas, negras, amarillas y algún hombre',
        url: 'https://elpais.com/opinion/2023-09-23/trata-de-blancas-negras-amarillas-y-algun-hombre.html',
        autor: 'Mabel Lozano'
      },
      {
        fecha: '31/08/2023',
        fuente: 'Cadena Ser',
        titulo: '"Merecen ser respetadas y escuchadas": la dedicatoria de Sarina Wiegman a la Selección Española Femenina',
        url: 'https://cadenaser.com/nacional/2023/08/31/merecen-ser-respetadas-y-escuchadas-la-emocionante-dedicacion-de-sarina-wiegman-al-equipo-espanol-tras-lograr-el-trofeo-a-mejor-entrenadora-del-ano-cadena-ser/',
        autor: null
      },
      {
        fecha: '25/08/2023',
        fuente: 'Público',
        titulo: 'Y el negacionismo machista aplaudió a Rubiales',
        url: 'https://blogs.publico.es/otrasmiradas/75420/y-el-negacionismo-machista-aplaudio-a-rubiales/',
        autor: 'Ana Bernal-Triviño'
      },
      {
        fecha: '31/07/2023',
        fuente: 'Feminismodigital',
        titulo: 'Ley de Inteligencia Artificial y principios de buena regulación',
        url: 'https://feminismodigital.org/ponencia/la-futura-ley-de-inteligencia-artificial-y-los-principios-de-buena-regulacion-en-las-tecnologias-disruptivas-como-oportunidad-una-aproximacion-desde-el-feminismo-juridico/',
        autor: 'Ana Rodríguez'
      },
      {
        fecha: '28/07/2023',
        fuente: 'Crónica Libre',
        titulo: 'Los Aznar o los fundadores del IVI, principales beneficiarios de la legalización del negocio de los vientres de alquiler en España',
        url: 'https://www.cronicalibre.com/feminismo-y-sociedad/aznar-ivi-negocio-vientres-alquiler/',
        autor: 'Berta O. García'
      },
      {
        fecha: '26/07/2023',
        fuente: 'Crónica Libre',
        titulo: 'La española Laura Favaro denuncia a la City University de Londres tras ser despedida por investigar las guerras de género',
        url: 'https://www.cronicalibre.com/feminismo-y-sociedad/la-espanola-laura-favaro-denuncia-a-la-city-university-de-londres-tras-ser-despedida-por-investigar-las-guerras-de-genero/',
        autor: 'Cristina Prieto'
      },
      {
        fecha: '19/07/2023',
        fuente: 'elComún.es',
        titulo: 'El miedo para enmudecer y paralizar el feminismo',
        url: 'https://elcomun.es/2023/07/19/el-miedo-para-enmudecer-y-paralizar-el-feminismo/',
        autor: 'Lidia Falcón'
      },
      {
        fecha: '18/07/2023',
        fuente: 'elComún.es',
        titulo: 'Las «extrañas» feministas',
        url: 'https://elcomun.es/2023/07/18/las-extranas-feministas/',
        autor: 'Inmaculada Morillo Blanco'
      },
      {
        fecha: '18/07/2023',
        fuente: 'elComún.es',
        titulo: 'Ese repugnante cóctel de llanto y chantaje (II)',
        url: 'https://elcomun.es/2023/07/18/ese-repugnante-coctel-de-llanto-y-chantaje-ii/',
        autor: 'Pilar Aguilar Carrasco'
      },
      {
        fecha: '20/06/2023',
        fuente: 'RTPA',
        titulo: 'Entrevista Begoña Piñero con repecto al acuerdo de Moriyón y Foro',
        url: 'https://www.rtpa.es/audio:20hoy%201%C2%AA%20edici%C3%B3n_1687244820.html',
        autor: null
      },
      {
        fecha: '18/06/2023',
        fuente: 'YouTube',
        titulo: 'El género daña" Sheila Jeffreys. Conversación con Amelia Valcárcel',
        url: 'https://www.youtube.com/watch?v=eFted3YlP8U',
        autor: null
      },
      {
        fecha: '16/06/2023',
        fuente: 'La La Nueva España',
        titulo: 'Placas de homenaje para las «pioneras» Rosario de Acuña y Concepción Arenal en el Botánico',
        url: 'https://www.lne.es/gijon/2023/06/16/placas-homenaje-pioneras-rosario-acuna-88761383.html',
        autor: 'Sergio García, Marcos León'
      },
      {
        fecha: '06/05/2023',
        fuente: 'YouTube',
        titulo: 'Ofrenda floral Rosario Acuña',
        url: 'https://www.youtube.com/watch?v=PvyrrkA11KM',
        autor: 'Intervención Ana Rodríguez'
      },
      {
        fecha: '06/03/2023',
        fuente: 'Youtube',
        titulo: 'GABRIELA MISTRAL, poesía, feminismo y justicia social',
        url: 'https://mail.google.com/mail/u/0/#inbox/FMfcgzGsmhdTFqkpMPggDpTCjhgZZZTj?projector=1',
        autor: 'Proyecto de Antolina de la Fuente'
      },{
        fecha: '28/01/2023',
        fuente: 'El Comercio',
        titulo: 'Les Comadres proponen al arzobispo como Felpeyu 2023',
        url: 'https://www.elcomercio.es/gijon/comadres-proponen-arzobispo-20230128002925-ntvo.html',
        autor: 'E.C'
      },
      {
        fecha: '27/09/2022',
        fuente: 'Youtube',
        titulo: 'Humor para reir y pensar el feminismo',
        url: 'https://www.youtube.com/watch?v=0Zs4ivkZG58',
        autor: 'Lula Gomez'
      },
      {
        fecha: '25/10/2022',
        fuente: 'El Comercio',
        titulo: 'Feminismo desde el humor',
        url: 'https://www.elcomercio.es/gijon/feminismo-humor-20221025002544-ntvo.html',
        autor: null
      },
      {
        fecha: '18/05/2022',
        fuente: 'El Comercio',
        titulo: 'Rosario Carracedo: «Queremos la abolición del sistema prostitucional»',
        url: 'https://www.elcomercio.es/gijon/rosario-carracedo-queremos-20220517140520-video.html',
        autor: 'Diego Abejón'
      },
      {
        fecha: '18/05/2022',
        fuente: 'La Nueva España',
        titulo: 'Jornada sobre la abolición de la prostitución',
        url: 'https://www.lne.es/gijon/2022/05/18/jornada-abolicion-prostitucion-66218913.html',
        autor: 'Ángel Glz'
      },
      {
        fecha: '17/05/2022',
        fuente: 'El Comercio',
        titulo: 'Begoña Piñero: «Era hora de que eso se contemplara»',
        url: 'https://www.elcomercio.es/gijon/begona-pinero-hora-20220517203506-video.html',
        autor: 'Diego Abejón'
      },
      {
        fecha: '31/10/2021',
        fuente: 'La sexta',
        titulo: 'El tren de la libertad de 2014 - Las feministas asturianas que pusieron en jaque a Gallardón: así movilizaron a España contra la ley del aborto del PP',
        url: 'https://www.lasexta.com/programas/donde-estabas-entonces/feministas-asturianas-que-pusieron-jaque-gallardon-asi-movilizaron-espana-ley-aborto_20211028617ae24bc1b52e0001325b33.html',
        autor: null
      },
      {
        fecha: '05/10/2021',
        fuente: 'La Voz de Asturias',
        titulo: 'Mabel Lozano: «Todos los niños llevan un pequeño cine porno en su bolsillo»',
        url: 'https://www.lavozdeasturias.es/noticia/asturias/2021/10/05/mabel-lozano-ninos-llevan-pequeno-cine-porno-bolsillo/00031633420954742642936.htm',
        autor: 'Marcos Gutiérrez'
      },
      {
        fecha: '05/10/2021',
        fuente: 'El Comercio',
        titulo: '«La prostitución ha migrado a fronteras digitales demasiado accesibles y salvajes»',
        url: 'https://www.elcomercio.es/gijon/prostitucion-migrado-fronteras-20211005000624-ntvo.html',
        autor: 'Guillermo Maese'
      },
      {
        fecha: '17/09/2021',
        fuente: 'La Nueva España',
        titulo: 'Tasia Aranguez, con "Les Comadres"',
        url: 'https://www.lne.es/gijon/2021/09/17/teresa-aranguez-les-comadres-57379744.html',
        autor: null
      },
      {
        fecha: '12/02/2021',
        fuente: 'La Nueva España',
        titulo: 'Un ciento de comadres online',
        url: 'https://www.lne.es/gijon/2021/02/12/ciento-comadres-online-34535257.html',
        autor: 'Lujan Palacios'
      },
      {
        fecha: '11/02/2021',
        fuente: 'El Comercio',
        titulo: "«El premio 'Comadre de Oro' es el más especial de todos»",
        url: 'https://www.elcomercio.es/gijon/premio-comadre-especial-20210211000945-ntvo.html',
        autor: 'Eugenia García'
      },
      {
        fecha: '10/02/2021',
        fuente: 'El Comercio',
        titulo: 'Una dosis de comadres contra el virus',
        url: 'juevesdecomadres/otros/Jueves de Comadres 2021/comercio.jpg',
        autor: 'Eduardo García'
      },
      {
        fecha: '10/02/2021',
        fuente: 'La Nueva España',
        titulo: 'La tertulia feminista entrega mañana sus premios con una reunión virtual de 100 mujeres',
        url: 'juevesdecomadres/otros/Jueves de Comadres 2021/La Nueva España.jpg',
        autor: 'Pablo Antuña'
      },
      {
        fecha: '05/02/2021',
        fuente: 'La Voz de Asturias',
        titulo: 'Les Comadres «premian» las «babayaes sexistas y misóginas» de Canteli',
        url: 'https://www.lavozdeasturias.es/noticia/gijon/2021/02/05/comadres-premian-babayaes-sexistas-misoginas-canteli/00031612539600397772600.htm',
        autor: null
      },
      {
        fecha: '05/02/2021',
        fuente: 'Europapress.es',
        titulo: "'Les Comadres' elige a Mabel Lozano 'Comadre de Oro' y otorga el 'Felpeyu' al 'Trifachito' y a Canteli el 'Babayu'",
        url: 'https://www.europapress.es/asturias/noticia-les-comadres-elige-mabel-lozano-comadre-oro-otorga-felpeyu-trifachito-canteli-babayu-20210205155724.html',
        autor: null
      },
      {
        fecha: '05/02/2021',
        fuente: 'La Vanguardia',
        titulo: "Les Comadres' elige a Mabel Lozano 'Comadre de Oro' y otorga el 'Felpeyu' al 'Trifachito' y a Canteli el 'Babayu'",
        url: 'https://www.lavanguardia.com/local/asturias/20210205/6226210/les-comadres-elige-mabel-lozano-comadre-oro-otorga-felpeyu-trifachito-canteli-babayu.amp.html',
        autor: null
      },
      {
        fecha: '05/02/2021',
        fuente: 'Playser',
        titulo: 'MABEL LOZANO, nueva Comadre de Oro',
        url: 'https://play.cadenaser.com/audio/1612531861987/?ssm=whatsapp',
        autor: 'Alicia Álvarez'
      },
      {
        fecha: '04/02/2021',
        fuente: 'La Nueva España',
        titulo: 'El alcalde de Oviedo y el "trifachito", premios "Babayu" y "Felpeyu" de "Les Comadres"',
        url: 'https://www.lne.es/gijon/2021/02/04/alcalde-oviedo-trifachito-premios-babayu-34081202.html',
        autor: null
      },
      {
        fecha: '29/01/2021',
        fuente: 'El Comercio',
        titulo: 'Miguel Bosé, entre los candidatos al "Felpeyu" de 2021',
        url: 'https://www.elcomercio.es/gijon/comadres-oro-felpeyu-candidatos-2021-20210129105253-nt.html',
        autor: 'Susana Tejedor'
      }, {
        fecha: '29/01/2021',
        fuente: 'La Nueva España',
        titulo: 'El cantante Miguel Bosé, el alcalde de Madrid, Martínez Almeida, y Monedero, entre los candidatos al "Felpeyu" de 2021',
        url: 'https://www.lne.es/gijon/2021/01/29/cantante-miguel-bose-alcalde-madrid-32258277.html',
        autor: null
      },
      {
        fecha: '26/01/2021',
        fuente: 'Cadena Ser',
        titulo: 'Intervención Begoña Piñero en hoy por hoy Gijón, hablando de Jueves de Comadres',
        url: 'https://play.cadenaser.com/audio/1611668828732/?ssm=whatsapp',
        autor: 'Alicia Álvarez'
      },
      {
        fecha: '27/10/2020',
        fuente: 'Youtube',
        titulo: '"La necesidad de un feminismo post-género"',
        url: 'https://www.youtube.com/watch?v=cvJoP6Vqrp0',
        autor: 'Rosa María Rodriguez Magda'
      },
      {
        fecha: '14/12/2020',
        fuente: 'Youtube',
        titulo: 'Pantalla para un debate: Desafios del ahora',
        url: 'https://www.youtube.com/watch?v=ZDRo9upzDD0',
        autor: 'Amelia Valcárcel'
      },
      {
        fecha: '04/12/2020',
        fuente: 'Youtube',
        titulo: 'Pantalla para un debate: Islám y mjer. La encrucijada',
        url: 'https://www.youtube.com/watch?v=Y1PDLGAkwS8&feature=youtu.be',
        autor: 'Najat El Hachmi'
      },
      {
        fecha: '23/10/2020',
        fuente: 'Youtube',
        titulo: 'Pantalla para un debate: La necesidad de un feminismo postgénero',
        url: 'https://www.youtube.com/watch?v=cvJoP6Vqrp0&feature=youtu.be',
        autor: 'Rosa María Rodríguez Magda'
      },
      {
        fecha: '01/07/2020',
        fuente: 'Play Ser',
        titulo: 'Begoña Piñero: "Estamos en un intento de diluir lo que es el feminismo"',
        url: 'https://play.cadenaser.com/audio/1593620732_078548/',
        autor: null
      },
      {
        fecha: '01/02/2020',
        fuente: 'El Comercio',
        titulo: '«Muchas mujeres tomaron conciencia del feminismo con el "tren de la libertad"»',
        url: 'https://www.elcomercio.es/gijon/mujeres-tomaron-conciencia-20200201011531-ntvo.html',
        autor: 'Marla Nieto'
      },
      {
        fecha: '31/01/2020',
        fuente: 'Onda cero',
        titulo: 'Entrevista Tren de la Libertad',
        url: 'https://www.ondacero.es/emisoras/asturias/gijon/mas-de-uno-gijon-31012020-con-guillermo-figueroa_202001315e3426690cf2e7657591ed97.html',
        autor: 'Guillermo Figuero'
      },
      {
        fecha: '31/01/2020',
        fuente: 'RTPA',
        titulo: "Presentación del llibru 'El Tren de la libertad' na Escuela de Comerciu de Xixón",
        url: 'https://www.rtpa.es/noticias-sociedad:Presentacion-del-llibru-%27El-Tren-de-la-libertad%27-na-Escuela-de-Comerciu-de-Xixon_111580466286.html',
        autor: null
      },
      {
        fecha: '30/01/2020',
        fuente: 'Cadena Ser',
        titulo: 'Un libro recoge la historia del Tren de la Libertad',
        url: 'https://cadenaser.com/emisora/2020/01/30/ser_gijon/1580389901_279188.html',
        autor: 'Alicia Álvarez'
      },
      {
        fecha: '05/12/2020',
        fuente: 'La Nueva España',
        titulo: 'Multitudinario homenaje a Rosario Fernández Hevia, la "jueza valiente"',
        url: 'https://www.lne.es/gijon/2019/12/05/multitudinario-homenaje-rosario-fernandez-hevia/2567587.html',
        autor: 'J.M.C.'
      },
      {
        fecha: '05/12/2020',
        fuente: 'La Voz de Asturias',
        titulo: '«Charo siempre fue la abogada de las causas perdidas y una rebelde»',
        url: 'https://www.lavozdeasturias.es/noticia/gijon/2019/12/04/charo-siempre-abogada-causas-perdidas-rebelde/00031575490701285657732.htm',
        autor: 'Marcos Gutiérrez'
      },
      {
        fecha: '22/11/2020',
        fuente: 'Youtube',
        titulo: 'Primer premio concedido por la Concejalía de Igualdad del Ayuntamiento de Móstoles a la Tertulia',
        url: 'https://www.youtube.com/watch?v=yaPbqeVXNLg&feature=emb_title',
        autor: null
      },
      {
        fecha: '24/05/2020',
        fuente: 'La Nueva España',
        titulo: 'In Memorian a Charo Fernández Hevia',
        url: 'http://lector.kioskoymas.com/epaper/iphone/homepage.aspx?isBookmark=true&locationHash=#_articlef6d8cd34-76d2-437e-9ad4-100f9a5b6b8a/waarticlef6d8cd34-76d2-437e-9ad4-100f9a5b6b8a/RN10FKIDVSJ6#_articlef6d8cd34-76d2-437e-9ad4-100f9a5b6b8a',
        autor: 'Begoña Piñero'
      },
      {
        fecha: '11/05/2020',
        fuente: 'Youtube',
        titulo: 'Homenaje Rosario Acuña',
        url: 'https://www.youtube.com/watch?v=kNorq9XajHs',
        autor: null
      },
      {
        fecha: '07/05/2020',
        fuente: 'RTPA',
        titulo: 'Vidas públicas, vidas privadas con Amelia Valcárcel',
        url: 'https://www.rtpa.es/video:vidas publicas, vidas privadas_551557272700.html',
        autor: null
      },
      {
        fecha: '08/03/2020',
        fuente: 'Youtube',
        titulo: 'Señores al borde de un ataque de nervios',
        url: 'https://www.youtube.com/watch?v=-t_Mapw66i4',
        autor: null
      }, {
        fecha: '14/12/2018',
        fuente: 'La Nueva España',
        titulo: 'Existen microviolencias sanitarias',
        url: 'https://www.lne.es/gijon/2018/12/14/existen-microviolencias-sanitarias-necesario/2396482.html',
        autor: 'Carmen Valls'
      },
      {
        fecha: '10/12/2018',
        fuente: 'Confilegal',
        titulo: 'Presunción de inocencia y violencia de género',
        url: 'https://confilegal.com/20181210-presuncion-de-inocencia-y-violencia-de-genero/amp/',
        autor: 'Susana Gisbert'
      },
      {
        fecha: '10/12/2018',
        fuente: 'Ser',
        titulo: 'Vox, llegan los tribunicios',
        url: 'https://cadenaser.com/ser/2018/12/03/sociedad/1543844998_272751.html',
        autor: 'Amelia Valcárcel'
      },
      {
        fecha: '01/12/2018',
        fuente: 'El Diario.es',
        titulo: 'A vueltas con la prostitución',
        url: 'https://www.eldiario.es/tribunaabierta/vueltas-prostitucion_6_809879009.html',
        autor: 'Beatriz Gimeno'
      },
      {
        fecha: '28/11/2018',
        fuente: 'Píkaramagazine',
        titulo: 'Lo que Merlí te esconde',
        url: 'http://www.pikaramagazine.com/2018/11/lo-que-merli-te-esconde/',
        autor: 'Esther Marín'
      },
      {
        fecha: '28/11/2018',
        fuente: 'Tribuna feminista',
        titulo: 'Le pido al Gobierno Vasco que no favorezca el proxenetismo (@urkulluLHK)',
        url: 'https://tribunafeminista.elplural.com/2018/11/le-pido-al-gobierno-vasco-que-no-favorezca-el-proxenetismo-urkullulhk/',
        autor: 'Ángeles Álvarez'
      },
      {
        fecha: '25/11/2018',
        fuente: 'El Comercio.es',
        titulo: 'Asturias muestra su «rechazo total» a la violencia machista',
        url: 'https://www.elcomercio.es/gijon/asturias-contra-maltrato-gijon-muestra-rechazo-20181125123857-nt.html',
        autor: null
      },
      {
        fecha: '21/11/2018',
        fuente: 'Público',
        titulo: 'El Gobierno vasco incluye la prostitución en su ley de Espectáculos Públicos y Actividades Recreativas',
        url: 'https://www.publico.es/politica/gobierno-vasco-incluye-prostitucion-ley-espectaculos-publicos-actividades-recreativas.html',
        autor: null
      },
      {
        fecha: '21/11/2018',
        fuente: 'El Diario.es',
        titulo: 'La Audiencia Nacional anula los estatutos del sindicato OTRAS porque entiende que la prostitución no es un trabajo',
        url: 'https://www.eldiario.es/economia/Audiencia-Nacional-estatutos-sindicato-prostitutas_0_838166337.html',
        autor: null
      },
      {
        fecha: '17/11/2018',
        fuente: 'La república',
        titulo: 'Feminismo y transexualidad: ¿Qué está ocurriendo?',
        url: 'https://larepublica.es/2018/11/17/feminismo-transexualidad-esta-ocurriendo/',
        autor: 'Marina Pibemat Vila'
      },
      {
        fecha: '17/11/2018',
        fuente: 'El Comercio.es',
        titulo: 'Asturias refuerza el sistema de protección a las víctimas',
        url: 'https://www.elcomercio.es/asturias/asturias-refuerza-sistema-20181117020759-ntvo.html',
        autor: 'Chelo Tuya'
      },
      {
        fecha: '15/11/2018',
        fuente: 'Levante',
        titulo: 'Declaración en favor del feminismo como Patrimonio de la Humanidad',
        url: 'https://www.levante-emv.com/valencia/2018/11/16/declaracion-favor-feminismo-patrimonio-humanidad/1795860.html',
        autor: null
      },
      {
        fecha: '12/11/2018',
        fuente: 'El salto',
        titulo: '300 feministas piden la retirada de la demanda para ilegalizar el sindicato OTRAS',
        url: 'https://www.elsaltodiario.com/sindicatos/300-feministas-piden-retirada-demanda-ilegalizar-sindicato-otras',
        autor: null
      },
      {
        fecha: '31/10/2018',
        fuente: 'Tribuna Feminista',
        titulo: 'El Consejo de Igualdad del Ayuntamiento de Oviedo en apoyo a la feminista Blanca Cañedo',
        url: 'https://tribunafeminista.elplural.com/2018/10/el-consejo-de-igualdad-del-ayuntamiento-de-oviedo-en-apoyo-a-la-feminista-blanca-canedo/',
        autor: null
      },
      {
        fecha: '26/10/2018',
        fuente: 'El Periódico',
        titulo: 'La diputada Ángeles Álvarez explota ante el papel de las mujeres en una exposición del Congreso',
        url: 'https://www.elperiodico.com/es/extra/20181026/angeles-alvarez-explota-mujeres-exposicion-congreso-destape-folcloricas-7111758',
        autor: null
      },
      {
        fecha: '25/10/2018',
        fuente: 'Tribuna feminista',
        titulo: 'Esencialismo de "Género"',
        url: 'https://tribunafeminista.elplural.com/2018/10/esencialismo-de-genero/',
        autor: 'Alicia Miyares'
      },
      {
        fecha: '15/10/2018',
        fuente: 'El País',
        titulo: 'Nace en Barcelona una plataforma que exige la abolición de la prostitución',
        url: 'https://elpais.com/ccaa/2018/10/15/catalunya/1539603168_637991.html',
        autor: null
      },
      {
        fecha: '09/10/2018',
        fuente: 'RTVE',
        titulo: 'Dos tercios del tiempo de cuidados no remunerado recae en las mujeres, según la OIT',
        url: 'http://www.rtve.es/noticias/20181009/dos-tercios-del-tiempo-cuidados-no-remunerado-recae-mujeres-segun-oit/1815520.shtml',
        autor: null
      },
      {
        fecha: '06/10/2018',
        fuente: 'Youtube',
        titulo: 'Alícia Miyares cuarta Ola de feminismo no somos solo cuerpos',
        url: 'https://www.youtube.com/watch?v=9pVIT8sUQB0',
        autor: null
      },{
        fecha: '23/09/2018',
        fuente: 'Público',
        titulo: 'Cuando lo que te compras es un bebé',
        url: 'https://www.publico.es/sociedad/vientres-alquiler-finde-compras-bebe.html',
        autor: null
    },
    {
        fecha: '16/09/2018',
        fuente: 'El Comercio.es',
        titulo: 'Son cada vez más jóvenes y las prácticas, más perversas',
        url: 'https://www.elcomercio.es/sociedad/medicos-mundo-prostitucuion-asturias-20180916005442-ntvo.html',
        autor: 'Josefina Barandiarán'
    },
    {
        fecha: '16/09/2018',
        fuente: 'El Comercio.es',
        titulo: 'La prostitución en Asturias, entre en el modelo abolicionista y la liberalización',
        url: 'https://www.elcomercio.es/sociedad/regulacion-prostitucion-asturias-20180916005443-ntvo.html',
        autor: 'Azahara Villacorta'
    },
    {
        fecha: '13/09/2018',
        fuente: 'El Economista.es',
        titulo: 'Carcedo apuesta por equiparar la gestación subrogada al tráfico de órganos',
        url: 'https://ecodiario.eleconomista.es/noticias-amp/9385778/Carcedo-apuesta-por-equiparar-la-gestacion-subrogada-al-trafico-de-organos',
        autor: null
    },
    {
        fecha: '09/08/2018',
        fuente: 'El Diario.es',
        titulo: 'Clandestinidad y mujeres condenadas: el difícil camino que llevó a España a lograr el aborto libre',
        url: 'https://www.eldiario.es/sociedad/clandestinidad-batalla-Espana-derecho-guardia_0_801770120.html',
        autor: null
    },
    {
        fecha: '03/07/2018',
        fuente: 'AmecoPress',
        titulo: 'El Ayuntamiento de Gijón apoya a Blanca Cañedo tras la denuncia de Abogados Cristianos',
        url: 'http://www.amecopress.net/spip.php?article17832',
        autor: null
    },
    {
        fecha: '29/06/2018',
        fuente: 'La Voz de Asturias',
        titulo: 'Blanca Cañedo: "Hay que ser valientes y no dejar pasar actuaciones como las de Abogados Cristianos"',
        url: 'https://www.lavozdeasturias.es/noticia/asturias/2018/10/29/canedo-valientes-dejar-pasar-actuaciones-abogados-cristianos/00031540808359536614181.htm',
        autor: null
    },
    {
        fecha: '28/06/2018',
        fuente: 'La Voz de Asturias',
        titulo: 'Abogados cristianos emprenden "una cruzada" contra la histórica feminista Blanca Cañedo',
        url: 'https://www.lavozdeasturias.es/noticia/asturias/2018/06/26/abogados-cristianos-emprenden-cruzada-contra-historica-feminista-blanca-canedo/00031530003268573316648.htm',
        autor: null
    },
    {
        fecha: '27/06/2018',
        fuente: 'La Nueva España',
        titulo: 'Apoyo político a la feminista Blanca Cañedo y al derecho a decidir de las mujeres',
        url: 'https://www.lne.es/gijon/2018/06/27/apoyo-politico-feminista-blanca-canedo/2309479.html',
        autor: null
    },
    {
        fecha: '14/06/2018',
        fuente: 'El Diario.es',
        titulo: 'Mafia reproductiva',
        url: 'https://www.eldiario.es/sociedad/clandestinidad-batalla-Espana-derecho-guardia_0_801770120.html',
        autor: null
    },
    {
        fecha: '10/06/2018',
        fuente: 'El País',
        titulo: '"Los vientres de alquiler son la utilización del cuerpo de las mujeres más pobres" Carmen Calvo',
        url: 'https://elpais.com/politica/2018/06/09/actualidad/1528557286_424452.html',
        autor: null
    },
    {
        fecha: '16/05/2018',
        fuente: 'RTPA',
        titulo: 'Cientos de Mujeres reclaman financiación para la lucha contra la violencia machista',
        url: 'http://www.rtpa.es/asturias:--Cientos-de-mujeres-reclaman-financiacion-para-la-lucha-contra-la-violencia-machista_111526471809.html',
        autor: null
    },
    {
        fecha: '02/01/2018',
        fuente: 'El Diario.es',
        titulo: 'El PP congela el Pacto contra la violencia',
        url: 'http://www.eldiario.es/_2b397ba9 vía @eldiarioes',
        autor: null
    },
    {
        fecha: '06/03/2018',
        fuente: 'Público',
        titulo: '¿Y si las mujeres políticas paramos el 8 de marzo?',
        url: 'http://blogs.publico.es/otrasmiradas/12926/y-si-las-mujeres-politicas-paramos-el-8-de-marzo/',
        autor: 'Purificación Causapié'
    },
    {
        fecha: '05/03/2018',
        fuente: 'Ser',
        titulo: '"Basta ya" significa estamos hartas',
        url: 'http://cadenaser.com/ser/2018/03/05/sociedad/1520245656_842952.html',
        autor: 'Marisa Soleto'
    },
    {
        fecha: '03/02/2018',
        fuente: 'YouTube',
        titulo: 'Reivindicativo discurso de Papa Charro en los Premios Goya',
        url: 'https://youtu.be/3BRWoWMEj6A',
        autor: null
    }, 
    {
        fecha: '24/01/2018',
        fuente: 'El País',
        titulo: 'Una no, las 2',
        url: 'https://elpais.com/elpais/2018/01/23/opinion/1516722446_539618.html',
        autor: 'Amelia Valcárcel'
    },
    {
        fecha: '17/01/2018',
        fuente: 'Agora',
        titulo: 'Sociedades patriarcales y neoliberales: sobre la urgencia de su análisis crítico en tiempos de "feminismos cool"',
        url: 'http://agora-online.es/2018/01/17/sociedades-patriarcales-y-neoliberales-sobre-la-urgencia-de-su-analisis-critico-en-tiempos-de-feminismos-cool/',
        autor: 'Ana de Miguel y Laura Favaro'
    },{
      fecha: '10/01/2018',
      fuente: 'El País',
      titulo: 'Un volcán en erupción. El universo femenino está elevando su voz de una forma cada vez más clara',
      url: 'https://elpais.com/elpais/2018/01/10/la_voz_de_inaki/1515573265_160999.html?id_externo_rsoc=whatsapp',
      autor: 'Iñaki Gabilondo'
  },
  {
      fecha: '20/12/2017',
      fuente: 'Huffpost',
      titulo: 'Gestación subrogada: explotación reproductiva y tráfico de bebés',
      url: 'http://www.huffingtonpost.es/inma-guillen/gestacion-subrogada-explotacion-reproductiva-y-trafico-de-bebes_a_23311871/?utm_hp_ref=es-homepage',
      autor: null
  },
  {
      fecha: '16/12/2017',
      fuente: 'Público',
      titulo: 'De Orantes a la manada: veinte hitos en la lucha contra la violencia machista en España',
      url: 'http://www.publico.es/sociedad/20-anos-asesinato-ana-orantes-orantes-manada-veinte-hitos-lucha-violencia-machista-espana.html',
      autor: null
  },
  {
      fecha: '03/12/2017',
      fuente: 'YouTube',
      titulo: 'Hola putero',
      url: 'https://youtu.be/cb7t10c-bIM',
      autor: null
  },
  {
      fecha: '11/11/2017',
      fuente: 'El Diario.es',
      titulo: 'Gracias al feminismo mantenemos la esperanza',
      url: 'http://m.eldiario.es/juanlusanchez/Gracias-feminismo-mantenemos-esperanza_6_709439059.html',
      autor: null
  },
  {
      fecha: '06/11/2017',
      fuente: 'YouTube',
      titulo: 'Una crítica feminista a la proposición de Ley de Igualdad LGTBI',
      url: 'https://www.youtube.com/watch?v=LQdcluY1D98&feature=youtu.be',
      autor: null
  },
  {
      fecha: '16/10/2017',
      fuente: 'YouTube',
      titulo: 'Muñecas para niñas y robots para niños. Un experimento de la BBC sobre los estereotipos de género',
      url: 'https://www.youtube.com/watch?v=Bj0fTHMXyok',
      autor: 'Aurora Izquierdo'
  },
  {
      fecha: '29/09/2017',
      fuente: 'El Comercio',
      titulo: 'Las entidades feministas reivindican el aborto libre',
      url: 'https://www.google.es/amp/www.elcomercio.es/gijon/entidades-feministas-reivindican-20170929004424-ntvo_amp.html',
      autor: null
  },
  {
      fecha: '29/09/2017',
      fuente: 'La Nueva España',
      titulo: 'Centenares de mujeres exigen el aborto libre en toda Europa',
      url: 'http://www.lne.es/gijon/2017/09/29/centenares-mujeres-exigen-aborto-libre/2170542.html',
      autor: null
  },
  {
      fecha: '27/09/2017',
      fuente: 'La Nueva España',
      titulo: 'Exigen el derecho al aborto: "libre, seguro y gratuito" en Europa',
      url: 'http://www.lne.es/gijon/2017/09/27/exigen-derecho-aborto-libre-seguro/2169472.html',
      autor: null
  },
  {
      fecha: '27/09/2017',
      fuente: 'El Comercio',
      titulo: 'Movilización por el derecho sexual de la mujer',
      url: 'http://www.elcomercio.es/gijon/movilizacion-derecho-sexual-20170927001326-ntvo.html',
      autor: null
  },
  {
      fecha: '22/09/2017',
      fuente: 'El País',
      titulo: 'La sombra de la prostitución',
      url: 'https://elpais.com/elpais/2017/09/22/opinion/1506073314_253133.html?id_externo_rsoc=whatsapp',
      autor: null
  },
  {
      fecha: '18/08/2017',
      fuente: 'El Comercio',
      titulo: 'Un personaje llamado Bárbara promoverá la igualdad entre los jóvenes en la calle y en las redes',
      url: 'http://www.elcomercio.es/aviles/personaje-llamado-barbara-20170818023314-ntvo.html',
      autor: null
  },
  {
      fecha: '28/07/2017',
      fuente: 'YouTube',
      titulo: 'Comisión Igualdad Congreso',
      url: 'https://www.youtube.com/watch?feature=youtu.be&v=qkuEtsiLzNc&app=desktop',
      autor: null
  },
  {
      fecha: '26/07/2017',
      fuente: 'YouTube',
      titulo: 'Campaña contra la violencia niñas y niños',
      url: 'https://youtu.be/C4YNQqwnDdU',
      autor: null
  },
  {
      fecha: '21/07/2017',
      fuente: 'El País',
      titulo: 'El País deja de publicar anuncios de contactos',
      url: 'https://www.google.es/amp/s/politica.elpais.com/politica/2017/07/20/actualidad/1500569329_932087.amp.html',
      autor: null
  },
  {
      fecha: '20/07/2017',
      fuente: 'Cadena Ser',
      titulo: 'Nuria Varela. El éxito del machismo es normalizar la desigualdad',
      url: 'http://play.cadenaser.com/audio/1500547956_166664/',
      autor: null
  },
  {
      fecha: '18/07/2017',
      fuente: 'Tribuna Feminista',
      titulo: '"Cuidado, Gaychirulo" El movimiento LGTB ante el reto feminista',
      url: 'http://www.tribunafeminista.org/2017/07/cuidado-gaychirulo-el-movimiento-lgtb-ante-el-reto-feminista/',
      autor: null
  },
  {
      fecha: '09/07/2017',
      fuente: 'Diario 16',
      titulo: 'El riesgo de no declarar contra el maltratador',
      url: 'http://diario16.com/dispensa-legal-riesgo-no-declarar-maltratador/',
      autor: null
  },
  {
      fecha: '08/07/2017',
      fuente: 'Infolibre',
      titulo: 'Carmen Calvo abronca a las juventudes de su partido por aprobar una resolución a favor de la maternidad subrogada',
      url: 'https://www.infolibre.es/noticias/politica/2017/07/16/carmen_calvo_psoe_abronca_las_juventudes_partido_por_haber_aprobado_una_resolucion_favor_maternidad_subrogada_67657_1012.html',
      autor: null
  },
  {
      fecha: '07/07/2017',
      fuente: 'Tribuna Feminista',
      titulo: 'La gestación subrogada lesiona derechos fundamentales de las gestantes',
      url: 'http://www.tribunafeminista.org/2017/07/comunicado-del-circulo-estatal-de-feminismos-sobre-gestacion-subrrogada/',
      autor: null
  },{
    fecha: '20/06/2017',
    fuente: 'Público',
    titulo: 'La protección de las mujeres no puede depender de una denuncia',
    url: 'http://blogs.publico.es/dominiopublico/19996/la-proteccion-de-las-mujeres-no-puede-depender-de-una-denuncia/',
    autor: null
},
{
    fecha: '18/06/2017',
    fuente: 'Europapress.es',
    titulo: 'El PSOE de Pedro Sánchez cierra la puerta a la maternidad subrogada',
    url: 'http://m.europapress.es/nacional/noticia-psoe-pedro-sanchez-cierra-puerta-maternidad-subrogada-20170617204522.html',
    autor: null
},
{
    fecha: '18/06/2017',
    fuente: 'El Diario.es',
    titulo: 'Vientres de alquiler: el PSOE no puede sucumbir al lenguaje tramposo de los deseos',
    url: 'https://www.eldiario.es/tribunaabierta/Vientres-alquiler-PSOE-sucumbir-lenguaje_6_655194504.html',
    autor: null
},
{
    fecha: '13/06/2017',
    fuente: 'El País',
    titulo: 'La agenda sobrevenida del Feminismo',
    url: 'https://elpais.com/elpais/2017/05/25/opinion/1495703140_313473.html?id_externo_rsoc=TW_CC',
    autor: null
},
{
    fecha: '12/06/2017',
    fuente: 'Tribuna Feminista',
    titulo: 'El feminismo envía una carta abierta a los delegados/as al 39º Congreso del PSOE',
    url: 'http://www.tribunafeminista.org/2017/06/el-feminismo-envia-una-carta-abierta-a-los-delegadoas-al-39o-congreso-del-psoe/',
    autor: null
},
{
    fecha: '12/01/2017',
    fuente: 'Confilegal',
    titulo: 'Desmontando el mito de la asimetría penal en violencia de género',
    url: 'https://confilegal.com/20170112-asimetria-penal-violencia-genero/amp/?__twitter_impression=true',
    autor: 'Susana Gisbert'
},
{
    fecha: '14/10/2016',
    fuente: 'Tribuna Feminista',
    titulo: '¿Ser "hermanas en Cristo" o en el puterío? ¿Ejercer o no la sororidad?',
    url: 'https://tribunafeminista.org/2016/10/ser-hermanas-en-cristo-o-en-el-puterio-ejercer-o-no-la-sororidad/',
    autor: 'Pilar Aguilar Carrasco'
},
{
    fecha: '07/07/2016',
    fuente: 'YouTube',
    titulo: 'Lenguaje inclusivo y lenguaje no sexista',
    url: 'https://www.youtube.com/watch?v=S93mWv0FfZE&t=384s',
    autor: 'Teresa Meana'
},
{
    fecha: '27/04/2016',
    fuente: 'YouTube',
    titulo: 'Mujer Roja, Mujer Republicana',
    url: 'https://www.youtube.com/watch?v=Q5BMGI2dDEk&feature=youtu.be',
    autor: null
},
{
    fecha: '15/03/2016',
    fuente: 'Tribuna Feminista',
    titulo: 'El Consejo de Europa dice NO a la Subrogación',
    url: 'https://tribunafeminista.elplural.com/2016/03/el-consejo-de-europa-dice-no-a-la-subrogacion/',
    autor: null
},
{
    fecha: '05/02/2016',
    fuente: 'La Nueva España',
    titulo: 'La peligrosa vuelta al azul y rosa',
    url: 'https://www.lne.es/gijon/2016/02/05/peligrosa-vuelta-azul-rosa/1878535.html',
    autor: 'L. Palacios / R. García'
},
{
    fecha: '30/01/2016',
    fuente: 'IMPRESIONADAS',
    titulo: 'Lenguaje y cultura como elementos de producción y legitimación de la violencia contra las mujeres',
    url: 'IMPRESIONADAS: XI Jornadas interrelación violencias machistas y VIH: PONENCIA I',
    autor: 'Mercedes Bengoechea'
},
{
    fecha: '07/01/2016',
    fuente: 'YouTube',
    titulo: 'Begoña Sánchez. AFA',
    url: 'https://www.youtube.com/watch?v=6uG-tr-gpg4&feature=youtu.be',
    autor: null
},
{
    fecha: '01/02/2015',
    fuente: 'YouTube',
    titulo: 'Fuenlabrada y el tren de la libertad',
    url: 'https://www.youtube.com/watch?v=NEIpZok901Y',
    autor: null
},
{
    fecha: '20/05/2014',
    fuente: 'Mujeres con Ciencia',
    titulo: 'Con A de astrónomas',
    url: 'https://mujeresconciencia.com/2014/05/20/con-a-de-astronomas/',
    autor: null
},
{
    fecha: '01/01/2010',  // antes era 'Enero 2010'
    fuente: 'RAW en Madrid',
    titulo: 'Intervención de la activista de RAWA en el acto del 21 de enero del 2010 en Madrid de Mujeres de Negro sobre la situación de las mujeres en Afganistán',
    url: 'deinteres/Afganistan.pdf',
    autor: null
},
{
    fecha: '01/09/2008',  // antes era 'Septiembre de 2008'
    fuente: 'El Comercio', 
    titulo: 'Pinchazos para el debate',
    url: 'deinteres/vph_01.pdf',
    autor: null
},
{
    fecha: '02/09/2008',
    fuente: 'Colectivo de Mujeres por la Salud NIELDA',
    titulo: 'Respuesta a la Directora de Salud Pública sobre la vacuna del papiloma',
    url: 'deinteres/vph_02.pdf',
    autor: null
},
{
    fecha: '18/09/2008',
    fuente: 'La Nueva España',
    titulo: 'Acerca de la vacuna del VPH: más no es necesariamente mejor',
    url: 'deinteres/vph_03.pdf',
    autor: null
},
{
    fecha: '21/02/2008',
    fuente: 'El País',
    titulo: 'Las mujeres en los programas electorales',
    url: 'deinteres/amelia.pdf',
    autor: 'Amelia Valcárcel'
},
{
    fecha: '23/03/2007',
    fuente: 'Ley Orgánica 3/2007',
    titulo: 'Ley Orgánica 3/2007, de 22 de marzo, para la igualdad efectiva de mujeres y hombres',
    url: 'deinteres/igualdad.pdf',
    autor: null
},
{
    fecha: '21/11/2007',
    fuente: 'Defensor del Pueblo',
    titulo: 'El defensor del pueblo considera "infundadas" las quejas del PP sobre la Casa Malva',
    url: 'deinteres/defensor.pdf',
    autor: null
},
{
    fecha: 'Marzo de 2007',
    fuente: 'Ley Orgánica 3/2007',
    titulo: 'Para la igualdad efectiva de mujeres y hombres',
    url: 'deinteres/igualdad.pdf',
    autor: null
},
{
    fecha: 'Diciembre 2006',
    fuente: 'Labrys nº10',
    titulo: 'Dossier España (Revista digital de estudios feministas)',
    url: 'http://www.unb.br/ih/his/gefem/labrys10/sumarioespanha.htm',
    autor: null
},
{
    fecha: 'Noviembre de 2006',
    fuente: 'AFA',
    titulo: 'La Asociación Feminista de Asturias celebra sus treinta años de existencia',
    url: 'deinteres/AFA30.pdf',
    autor: null
},
{
    fecha: 'Noviembre de 2006',
    fuente: 'Xega-Tríbadas',
    titulo: 'Comunicado ante la agresión sufrida por cuatro mujeres en Bimenes',
    url: 'deinteres/Bimenes.pdf',
    autor: null
},
{
    fecha: 'Octubre de 2006',
    fuente: 'Cátedra - Colección Feminismos',
    titulo: 'La gran diferencia y sus pequeñas consecuencias... para las luchas de las mujeres',
    url: 'deinteres/celia.htm',
    autor: 'Celia Amorós'
},
{
    fecha: 'Septiembre 2006',
    fuente: 'El País',
    titulo: 'Viñeta de El Roto',
    url: 'deinteres/vph_04.gif',
    autor: 'El Roto'
},
{
    fecha: '26/11/2006',
    fuente: 'El País',
    titulo: 'Amelia Valcárcel. Una filósofa en combate',
    url: 'deinteres/amelia-eps.pdf',
    autor: null
},
{
    fecha: '08/09/2006',
    fuente: 'Medalla de Asturias',
    titulo: 'Amelia Valcárcel, Medalla de Asturias en su categoría de plata',
    url: 'deinteres/medallamelia.pdf',
    autor: null
},
{
    fecha: '30/04/2006',
    fuente: 'El País',
    titulo: 'Necrológica de María José Urruzola Zabalza',
    url: 'deinteres/urruzola.pdf',
    autor: 'Lidia Falcón'
},
{
    fecha: '21/04/2005',
    fuente: 'Mujeres en Red',
    titulo: 'Que Dios nos coja confesadas... Carta a Las Feministas: sobre los errores doctrinales y de interpretación del Cardenal Joseph Ratzinger, hoy Benedicto XVI',
    url: 'http://www.mujeresenred.net/news/article.php3?id_article=162',
    autor: 'Alicia Miyares'
}

 ];

  if (loading) return <div>Cargando...</div>;
  if (error) return <div>{error}</div>;

  // Ordenar los datos por fecha (más reciente primero)
  const sortedData = [...allData].sort((a, b) => {
    const dateA = new Date(a.fecha.split('/').reverse().join('-'));
    const dateB = new Date(b.fecha.split('/').reverse().join('-'));
    return dateB - dateA;
  });

  const fuenteLogos = {
     'El País': '/logos/el-pais.svg',
  'elDiario': '/logos/eldiario.svg',
  'El Diario.es': '/logos/eldiario.svg',
  'El Comercio': '/logos/el-comercio.svg',
  'El Comercio.es': '/logos/el-comercio.svg',
  'La Nueva España': '/logos/lne.png',
  'RTPA': '/logos/rtpa.png',
  'Ser Gijón': '/logos/ser.png',
  'Voz de Asturias': '/logos/voz-asturias.png',
  'Instagram': '/logos/instagram.png',
  'Público': '/logos/publico.png',
  'Europapress': '/logos/europapress.png',
  'elComún.es': '/logos/elcomun.jpg',
  'Crónica Libre': '/logos/cronica-libre.png',
  'InfoLibre': '/logos/infolibre.svg',
  'Agora': '/logos/agora.png',
  'YouTube': '/logos/youtube.png',
  'Youtube': '/logos/youtube.png',
  'El Mundo': '/logos/elmundo.png',
  'AmecoPress': '/logos/amecopress.png',
  'Ser': '/logos/ser.png',
  'Razón25': '/logos/razon25.png',
  'Tribuna Feminista': '/logos/tribuna-feminista.png',
  'nuevaalcarria': '/logos/nuevaalcarria.png',
  'Periodismo Alternativo': '/logos/periodismo-Alternativo.png',
  'Europa Press': '/logos/europapress.png',
  'La Independent': '/logos/la-independiente.svg',
  'MIGIJON': '/logos/migijon.png',
  'MIGIJÓN': '/logos/migijon.png',
  'Nueva España': '/logos/lne.png',
  'La Voz de Asturias': '/logos/voz-asturias.png',
  'La sexta': '/logos/lasexta.svg',
  'Asturias 24': '/logos/asturias24.png',
  'El digital de Asturias': '/logos/digitalasturias.png',
  'Vientos de cambio justo': '/logos/vientos-cambio-justo.png',
  'elmundotoday': '/logos/elmundotoday.png',
  'amecopress': '/logos/amecopress.png',
  'Cadena Ser': '/logos/ser.svg',
  'razon25': '/logos/razon25.png',
  'La Vanguardia': '/logos/lavanguardia.png',
  'Onda Cero': '/logos/ondacero.png',
  'IVOOX': '/logos/ivooox.svg',
  'pdf': '/logos/pdf.png',
  'La Razón': '/logos/razon25.png',
  'Contra el borrado de las mujeres': '/logos/contraelborradodelasmujeres.png',
  'Nortes': '/logos/nortes.png',
  'EFE': '/logos/efe.webp',
  'Norte de Castilla': '/logos/nortedecastilla.png',
  'El Blog de Salagre': '/logos/elblogdesalagre.png',
  'RTVE': '/logos/rtve.png'
  };

  return (
    <HemerotecaContainer>
      <Header>
        <img src="/logos/prensa.jpg" alt="Prensa icon" />
        <h2>Artículos e informaciones de interés</h2>
      </Header>
      
      {sortedData.map((articulo, index) => (
        <ArticuloContainer key={index}>
          {fuenteLogos[articulo.fuente] ? (
            <FuenteLogo 
              src={fuenteLogos[articulo.fuente]} 
              alt={articulo.fuente} 
            />
          ) : (
            <DefaultBullet />
          )}
          <ArticuloInfo>
            <Fecha>{articulo.fecha}</Fecha>
            {articulo.fuente && <Fuente>{articulo.fuente}</Fuente>}
            <ArticuloLink href={articulo.url} target="_blank" rel="noopener noreferrer">
              {articulo.titulo}
            </ArticuloLink>
            {articulo.autor && <Autor>{articulo.autor}</Autor>}
          </ArticuloInfo>
        </ArticuloContainer>
      ))}
    </HemerotecaContainer>
  );
};

export default OtrasNoticias; 